import React from "react";
import AceEditor from "react-ace";

function AcasAir(props) {
  const { customer, shippingAddress_obj, notes, name, billing_address } =
    props.data[0];
  const address = shippingAddress_obj
    ? shippingAddress_obj.state.split(",")
    : "";
  const addressPlus = address ? address[2]?.split(" ") : "";

  const data = `
WASUCCR
.BCBPEJ8
FRI
180-30828475-0000297237GJ
WBL/XIY/T1/K2.639/${notes ? notes : name}
SHP/${customer.name ? customer.name : customer.first_name}
/10025 Siempre Viva Rd
/San Diego
/CA
CNE/${customer.name ? customer.name : customer.first_name}
/${address[0] ? address[0] : billing_address.address1}
/ ${address[1] ? address[1] : billing_address.city}/
        ${addressPlus[1] ? addressPlus[1] : billing_address.province_code}
/US/${addressPlus[2] ? addressPlus[2] : billing_address.zip}
  `;

  return (
    <div
      className="mt-2 w-full h-full text-justify pb-10 pr-10 pl-10"
      style={{
        overflowY: "scroll",
        wordWrap: "break-word",
        marginBottom: 20,
      }}
    >
      <AceEditor
        width="100%"
        height="100%"
        mode="javascript"
        theme="tomorrow"
        name="editor"
        value={data}
        fontSize={14}
        editorProps={{ $blockScrolling: true }}
      />
      {/* <div>WASUCCR</div>
      <div>.BCBPEJ8</div>
      <div>FRI</div>
      <div>180-30828475-0000297237GJ</div>
      <div>WBL/XIY/T1/K2.639/{notes ? notes : name}</div>
      <div>SHP/{customer.name ? customer.name : customer.first_name}</div>
      <div>/10025 Siempre Viva Rd</div>
      <div>/San Diego</div>
      <div>/CA</div>
      <div>CNE/{customer.name ? customer.name : customer.first_name}</div>
      <div>/{address[0] ? address[0] : billing_address.address1}</div>
      <div>
        /{address[1] ? address[1] : billing_address.city}/
        {addressPlus[1] ? addressPlus[1] : billing_address.province_code}
      </div>
      <div>/US/{addressPlus[2] ? addressPlus[2] : billing_address.zip}</div> */}
    </div>
  );
}

export default AcasAir;
