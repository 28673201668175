import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import FacturaXml from "../lib/FacturaXml";
import pdf from "../lib/Archivo.pdf";
import CartaPorteXml from "../lib/CartaPorteXml";
import Edi358 from "../lib/Edi358";
import AcasAir from "../lib/AcasAir";
import Type86 from "../lib/Type86";
import Acas from "../lib/Acas";
import { UseLocalStorage } from "./hooks/UseLocalStorage";
import { size } from "lodash";
import swal from "sweetalert";

function DetailFunctionOrder(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [seleccionado, setseleccionado] = useState("");
  const [label, setlabel] = useState("");
  const [openLabel, setopenLabel] = useState(false);
  const [order, setOrder] = UseLocalStorage("orderusd", "");

  const gridRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  const OpcionesAcciones = (p) => {
    let currentCategory = [];

    currentCategory =
      order && order.filter((order) => order.idT1 === props.data.id);

    if (p.data.make === "Factura") {
      return (
        <button
          type="submit"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            setseleccionado("Factura de exportación Mexicana XML");
            setIsOpen(true);
          }}
        >
          Show XML
        </button>
      );
    } else if (p.data.make === "Etiqueta") {
      return (
        <>
          {size(currentCategory) > 0 ? (
            <button
              type="submit"
              className="mr-4 text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
              onClick={() => {
                setlabel(currentCategory[0].etiqueta);
                setTimeout(() => {
                  setopenLabel(true);
                }, 100);
              }}
            >
              Show Ticket
            </button>
          ) : (
            <button
              type="submit"
              className="mr-4 text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
              onClick={() =>
                swal({
                  title: "No cuenta con etiqueta",
                  icon: "error",
                })
              }
            >
              Sin etiqueta
            </button>
          )}
        </>
      );
    } else if (p.data.make === "Carta Porte") {
      return (
        <div className="flex flex-row">
          <button
            type="submit"
            className="mr-4 text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
            onClick={() => {
              setseleccionado("Carta Porte PDF");
              setIsOpen(true);
            }}
          >
            Show PDF
          </button>
          <button
            type="submit"
            className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
            onClick={() => {
              setseleccionado("Carta Porte XML");
              setIsOpen(true);
            }}
          >
            Show XML
          </button>
        </div>
      );
    } else if (p.data.make === "Truck E-Manifest") {
      return (
        <button
          type="submit"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            setseleccionado("Truck E-Manifest EDI");
            setIsOpen(true);
          }}
        >
          Show EDI 358
        </button>
      );
    } else if (p.data.make === "ACAS") {
      return (
        <button
          type="submit"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            setseleccionado("ACAS");
            setIsOpen(true);
          }}
        >
          Show EDI
        </button>
      );
    } else if (p.data.make === "AIr AMS") {
      return (
        <button
          type="submit"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            setseleccionado("Air AMS");
            setIsOpen(true);
          }}
        >
          Show EDI
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26]  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            setseleccionado("Type 86");
            setIsOpen(true);
          }}
        >
          Show EDI
        </button>
      );
    }
  };

  const OpcionesCosto = (p) => {
    let currentCategory = [];
    currentCategory =
      order && order.filter((order) => order.idT1 === props.data.id);

    if (p.data.make === "Etiqueta") {
      return (
        <>
          {size(currentCategory) > 0 ? (
            <div>{currentCategory[0].shipPrice.toFixed(2)}</div>
          ) : (
            <div>0.00</div>
          )}
        </>
      );
    } else {
      return <div>0.25</div>;
    }
  };

  const [columnDefs] = useState([
    {
      headerName: "Módulo",
      field: "make",
      resizable: true,
      width: 200,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      headerName: "Acciones",
      field: "make",
      cellRendererFramework: OpcionesAcciones,
      resizable: true,
      width: 250,
    },
    {
      headerName: "Costo (USD)",
      field: "make",
      cellRendererFramework: OpcionesCosto,
      resizable: true,
      width: 140,
      cellStyle: () => ({
        display: "flex",
        alignItems: "right",
        justifyContent: "right",
      }),
    },
  ]);

  const [rowData] = useState([
    {
      make: "Label",
    },
    {
      make: "Invoice",
    },
    {
      make: "Port Card",
    },
    {
      make: "Truck E-Manifest",
    },
    {
      make: "ACAS",
    },
    {
      make: "Air AMS",
    },
    {
      make: "Type 86",
    },
  ]);

  return (
    <>
      <div
        className="ag-theme-alpine pt-6 pb-2 pl-5 pr-5"
        style={{ height: 282, width: window.innerWidth - "20%" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          ref={gridRef}
          rowHeight={55}
        ></AgGridReact>
      </div>

      {isOpen && (
        <>
          <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="w-full h-full" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25 " />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto items-center justify-center">
                <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left shadow-xl transition-all items-center justify-center ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                      >
                        <div className="ml-5">{seleccionado}</div>
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-toggle="defaultModal"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </Dialog.Title>
                      {seleccionado ===
                        "Factura de exportación Mexicana XML" && (
                        <FacturaXml data={[props.data]}></FacturaXml>
                      )}
                      {seleccionado === "Carta Porte PDF" && (
                        <iframe
                          title="Label"
                          src={pdf}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      )}

                      {seleccionado === "Carta Porte XML" && (
                        <CartaPorteXml data={[props.data]}></CartaPorteXml>
                      )}
                      {seleccionado === "Truck E-Manifest EDI" && (
                        <Edi358 data={[props.data]}></Edi358>
                      )}
                      {seleccionado === "Air AMS" && (
                        <AcasAir data={[props.data]}></AcasAir>
                      )}
                      {seleccionado === "ACAS" && (
                        <Acas data={[props.data]}></Acas>
                      )}
                      {seleccionado === "Type 86" && (
                        <Type86 data={[props.data]}></Type86>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
      {openLabel && (
        <>
          <Transition show={openLabel} as={Fragment}>
            <Dialog
              as="div"
              className="w-full h-full"
              onClose={() => {
                setopenLabel(false);
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25 " />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto items-center justify-center">
                <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left shadow-xl transition-all items-center justify-center ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                      >
                        {/* <div>Información EDI</div> */}
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-toggle="defaultModal"
                          onClick={() => {
                            setopenLabel(false);
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </Dialog.Title>
                      {/* <iframe
                        title="Label"
                        className="pb-10 pr-10 pl-10"
                        src={label}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      /> */}
                      <div
                        style={{
                          overflowY: "scroll",
                          height: "100%",
                        }}
                      >
                        <img className="pt-4 pr-10 pl-10" src={label} alt="" />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </>
  );
}

export default DetailFunctionOrder;
