import React from "react";
import { useNavigate } from "react-router-dom";
// import generateJSON from "../lib/generateJSON";
// import { UseLocalStorage } from "../components/hooks/UseLocalStorage";
// import { size } from "lodash";

function AmazonShop(props) {
  let navigate = useNavigate();
  // const [order, setOrder] = UseLocalStorage("orders", "");
  // var informacionOrden = {};

  // function generateUUID() {
  //   var d = new Date().getTime();
  //   var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
  //     /[xy]/g,
  //     function (c) {
  //       var r = (d + Math.random() * 16) % 16 | 0;
  //       d = Math.floor(d / 16);
  //       return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  //     }
  //   );
  //   return uuid;
  // }

  // const handledBuyClick = () => {
  //   //TODO generar aleatoreamente el ID de la Orden
  //   // eslint-disable-next-line no-unused-vars
  //   const json = generateJSON({
  //     orderNumber: generateUUID(),
  //     dateOrder: new Date(),
  //   });

  //   //TODO Pushearlo al Array que existe en el local storage "orders"
  //   if (size(order) > 0) {
  //     informacionOrden = [...order, json];
  //     setOrder(informacionOrden);
  //   } else {
  //     informacionOrden = [json];
  //     setOrder(informacionOrden);
  //   }
  //   navigate("/dashboard");
  // };

  return (
    <>
      {/* component */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');\nhtml {font-family: \"PT Sans\", sans-serif;}\n",
        }}
      />

      <div className="flex flex-col">
        <div
          className="pt-3 pb-1 px-3 flex flex-col "
          style={{ backgroundColor: "#1c366b" }}
        >
          <div className="flex flex-row justify-between items-center mb-2 py-1">
            <div className="flex flex-row items-center">
              <svg
                className="w-6 h-6 text-white mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/*<svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6"
                id="svg1936"
                version={1.0}
                viewBox="0 0 1000 301.4"
              >
                <path
                  id="path8"
                  fill="#f90"
                  fillOpacity={1}
                  fillRule="evenodd"
                  stroke="none"
                  d="M620 236a389 389 0 01-477-34c-5-6-1-12 6-9a528 528 0 00463 29c10-4 18 7 8 14"
                />
                <path
                  id="path10"
                  fill="#f90"
                  fillOpacity={1}
                  fillRule="evenodd"
                  stroke="none"
                  d="M645 208c-8-9-50-4-68-2-6 0-7-5-2-8 33-23 88-17 94-9 7 8-1 63-33 89-4 4-9 2-7-4 7-17 23-56 16-66"
                />
                <path
                  id="path12"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M578 33V10c0-3 3-6 6-6h102c3 0 5 3 5 6v20c0 3-2 7-7 14l-53 75c20 0 40 3 58 13 4 2 5 5 5 8v25c0 3-3 7-7 5-31-17-73-18-108 0-3 2-7-2-7-5v-23c0-4 0-10 4-16l61-87h-53c-3 0-6-3-6-6"
                />
                <path
                  id="path14"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M207 175h-31c-3-1-5-3-6-6V11c0-4 3-6 6-6h29c3 0 6 2 6 5v21c8-20 22-29 41-29 20 0 32 9 40 29 8-20 25-29 43-29 13 0 27 5 36 17 10 13 8 33 8 50v100c0 3-3 6-6 6h-31c-3-1-6-3-6-6V85c0-7 1-24-1-30-2-11-9-14-18-14-7 0-15 5-18 13s-3 22-3 31v84c0 3-3 6-6 6h-31c-3-1-6-3-6-6V85c0-18 3-44-19-44s-21 25-21 44v84c0 3-3 6-6 6"
                />
                <path
                  id="path16"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M779 2c46 0 71 39 71 89 0 49-28 87-71 87-45 0-70-39-70-89 0-49 25-87 70-87m0 32c-23 0-24 31-24 50 0 20 0 61 24 61s25-33 25-54c0-13 0-29-5-42-3-11-10-15-20-15"
                />
                <path
                  id="path18"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M909 175h-31c-3-1-5-3-5-6V10c0-3 3-5 6-5h29c2 0 4 2 5 4v25h1c8-22 20-32 42-32 14 0 27 5 36 18 8 13 8 34 8 50v100c0 2-3 5-6 5h-31c-3-1-5-3-5-5V83c0-17 2-42-20-42-7 0-14 5-18 12-4 10-5 20-5 30v86c0 3-2 6-6 6"
                />
                <path
                  id="path28"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M497 99c0 12 0 22-6 33-5 8-13 14-21 14-12 0-19-9-19-23 0-26 24-31 46-31v7m31 75c-2 2-5 2-7 1-10-9-12-13-18-21-17 18-29 23-51 23-26 0-46-16-46-48 0-25 13-43 33-51 17-7 40-9 58-11v-4c0-7 0-16-4-22s-11-8-17-8c-12 0-23 6-25 18-1 3-3 6-5 6l-30-3c-3-1-6-3-5-7 7-36 40-47 69-47 15 0 35 4 47 15 15 14 13 33 13 54v48c0 14 6 21 12 29 2 2 2 6 0 8l-24 20"
                />
                <path
                  id="path30"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M91 99c0 12 0 22-6 33-5 8-12 14-21 14-12 0-19-9-19-23 0-26 24-31 46-31v7m31 75c-2 2-5 2-7 1-10-9-12-13-18-21-17 18-29 23-51 23-26 0-46-16-46-48 0-25 14-43 33-51 17-7 40-9 58-11v-4c0-7 1-16-4-22-3-6-11-8-17-8-12 0-22 6-25 18 0 3-2 6-5 6l-30-3c-3-1-6-3-5-7C12 11 45 0 74 0c15 0 35 4 47 15 15 14 14 33 14 54v48c0 14 6 21 11 29 2 2 3 6 0 8l-24 20"
                />
              </svg>*/}
            </div>
            <div className="flex flex-row">
              <div
                className="mr-10 bg-blue-400 rounded-md p-1 pl-4 pr-4 cursor-pointer text-sky-900 font-medium "
                onClick={() => navigate("/")}
              >
                Home
              </div>

              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-row bg-white rounded-lg h-10">
            <input
              type="text"
              className="bg-white rounded-lg flex-grow p-2 focus:outline-none"
              defaultValue="Checo Perez"
              placeholder="Search Amazon"
              name="search"
              id="search"
            />
            <div className="bg-yellow-400 p-2 rounded-lg mx-auto">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={3}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>
        </div>
        {/* <div
          className="p-3 text-white text-sm"
          style={{ backgroundColor: "#37475a" }}
        >
          <div className="flex flex-row items-center">
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <p>Deliver to Marco</p>
          </div>
        </div> */}
        {/* <div className="py-3 border-t-200 border-b-200 border-t border-b font-black mb-2 h-12" /> */}
        <div className="pb-4 px-4 mt-2">
          <div className="items-center flex flex-row">
            <span style={{ color: "#007185" }} className="text-sm flex-grow">
              Brand: Fuel For Fans
            </span>
            <div>
              <svg
                className="w-4 h-4 -mr-1 inline"
                fill="#ffa41c"
                stroke="#e18032"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              <svg
                className="w-4 h-4 -mr-1 inline"
                fill="#ffa41c"
                stroke="#e18032"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              <svg
                className="w-4 h-4 -mr-1 inline"
                fill="#ffa41c"
                stroke="#e18032"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              <svg
                className="w-4 h-4 -mr-1 inline"
                fill="#ffa41c"
                stroke="#e18032"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              <svg
                className="w-4 h-4 -mr-1 inline"
                fill="#ffa41c"
                stroke="#e18032"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              {/* <svg
                className="w-4 h-4 -mr-1 inline"
                fill="none"
                stroke="#e18032"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg> */}
              <span style={{ color: "#007185" }} className="text-xs ml-2">
                182 ratings
              </span>
            </div>
          </div>
          <h1 className="text-regular font-regular leading-tight text-gray-800">
            Red Bull Racing F1 2022 Sergio Checo Perez Navy Hat
          </h1>
          <img
            src="https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg"
            className="mx-auto h-56 my-4"
            alt=""
            srcSet=""
          />
          <div className="py-3 border-t-200 border-b-200 border-t border-b font-black mb-2">
            <h1>Style: Baseball</h1>
          </div>
          <div className="flex flex-row mb-2">
            <p className="font-light">US$</p>
            <p className="text-4xl font-light">50</p>
            <p className="font-light">00</p>
          </div>
          {/* <span className="text-regular leading-tight mb-2">
            US$19.14 shipping and import duty deposit to USA{" "}
            <a href="/" style={{ color: "#007185" }}>
              Details
            </a>
          </span>
          <div className="mt-2">
            Delivery on{" "}
            <span className="text-regular leading-tight font-bold ">
              Tuesday, September 13
            </span>
          </div>
          <div className="mt-2">
            <a href="/" style={{ color: "#007185" }}>
              Deliver to USA
            </a>
          </div> */}

          <div className="p-3 border border-gray-300 rounded-lg font-black my-3">
            <h1 className="text-gray-800 mb-1">Brand: Fuel for Fans</h1>
            <div className="flex flex-row">
              <img
                src="https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg"
                className="w-1/4"
                alt=""
              />
              <div className="ml-2 w-3/4">
                <p
                  className="truncate font-light leading-tight"
                  style={{ color: "#007185" }}
                >
                  Red Bull Racing F1 2022 Sergio Checo Perez Navy Hat
                </p>
                <div className="items-center flex flex-row">
                  <div>
                    <svg
                      className="w-4 h-4 -mr-1 inline"
                      fill="#ffa41c"
                      stroke="#e18032"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                      />
                    </svg>
                    <svg
                      className="w-4 h-4 -mr-1 inline"
                      fill="#ffa41c"
                      stroke="#e18032"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                      />
                    </svg>
                    <svg
                      className="w-4 h-4 -mr-1 inline"
                      fill="#ffa41c"
                      stroke="#e18032"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                      />
                    </svg>
                    <svg
                      className="w-4 h-4 -mr-1 inline"
                      fill="#ffa41c"
                      stroke="#e18032"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                      />
                    </svg>
                    <svg
                      className="w-4 h-4 -mr-1 inline"
                      fill="#ffa41c"
                      stroke="#e18032"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                      />
                    </svg>
                    {/* <svg
                      className="w-4 h-4 -mr-1 inline"
                      fill="none"
                      stroke="#e18032"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                      />
                    </svg> */}
                    <span className="text-sm text-gray-600 font-light ml-2">
                      182 ratings
                    </span>
                  </div>
                </div>
                <p className="text-red-700 font-light leading-tight">$50.00</p>
                {/* <p className="text-red-700 font-light leading-tight">
                  Only 6 left in stock - order soon.
                </p> */}
              </div>
            </div>
          </div>
          <div className="flex flex-row my-4 space-x-4">
            <button
              className="rounded w-1/2 px-2 py-3  border border-blue-400 bg-gradient-to-b from-blue-300 to-blue-500"
              // onClick={handledBuyClick}
              onClick={() => navigate("/checkoutamazon")}
            >
              Add to cart
            </button>
            <button
              className="rounded w-1/2 px-2 py-3 border border-blue-700 bg-gradient-to-b from-blue-500 to-blue-600"
              // onClick={handledBuyClick}
              onClick={() => navigate("/checkoutamazon")}
            >
              Buy now
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AmazonShop;
