import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "CompanyName=Flexible Staffing Services,LicensedGroup=Flexible Staffing Services,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-033177,SupportServicesEnd=22_September_2023_[v2]_MTY5NTMzNzIwMDAwMA==891dfb8f3b0f44fb648a8320cfc96e1c"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
