import React, { useEffect, useState, useRef, useCallback } from "react";
import ModalEdi from "../components/ModalEdi";
import { size } from "lodash";
import { UseLocalStorage } from "../components/hooks/UseLocalStorage";
import swal from "sweetalert";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import ModalInfo from "./ModalInfo";
import ClipLoader from "react-spinners/ClipLoader";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function Dashboard(props) {
  const [order, setOrder] = UseLocalStorage("orders", "");
  let [isOpen, setIsOpen] = useState(false);
  const [id, setid] = useState("");
  const [seleccionados, setSeleccionados] = useState([]);
  const [tokenlogin, setTokenlogin] = useState("");
  const [loading, setloading] = useState(true);
  const gridRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(id) {
    // setIsOpen(true);
    window.open(`https://www.paypal.com/checkoutnow?token=${id}`);
  }

  useEffect(() => {
    setInterval(() => {
      const pedidos = JSON.parse(localStorage.getItem("orders"));
      if (pedidos) {
        setOrder(
          pedidos.sort((a, b) => {
            a = new Date(a.dateOrder).getTime();
            b = new Date(b.dateOrder).getTime();
            return a > b ? -1 : a < b ? 1 : 0;
          })
        );
        setloading(false);
      } else {
        setOrder([]);
      }
    }, 1000);
    fetchApiLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchapidelete = async (
    orderNumber,
    adress,
    idProduct,
    nameProduct,
    name
  ) => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      user: `{"token":"${tokenlogin}"}`,
      "Content-Type": "application/json",
    };

    fetch("https://easystreet.t1paginas.com/api/eshop/cart/0", {
      method: "DELETE",
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        handledClick(orderNumber, adress, idProduct, nameProduct, name);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchApiLogin = async () => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      info: "U2FsdGVkX18TF+R2rNdPmhz6SzkrAsnAInqrDOnysqekzIpMeoxs7cOhQ+jBkKWZkTswC1+Q4VYbtxGJHl/tHSGs4WsGx6fbUSNvtDQgMgU=",
    });

    fetch("https://easystreet.t1paginas.com/api/eshop/login", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        console.log(data.data.user.token);
        setTokenlogin(data.data.user.token);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchApiPaso1 = async (idProduct) => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      user: `{"token":"${tokenlogin}"}`,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      product_id: idProduct,
      name: "Fresh",
      qty: 1,
      uPrice: 10,
      discountName: "",
      categories: [],
      qtyAvailable: 1000,
      weight: 0,
      isPackage: false,
      appliesTo: ["store", "eshop"],
      integrations: [],
      isCustomProduct: false,
      note: "",
    });

    fetch("https://easystreet.t1paginas.com/api/eshop/cart", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchApiPaso2 = async (idOrden, adress, nameProduct, name) => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      user: `{"token":"${tokenlogin}"}`,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      paymethod: "PayPal",
      paymethod_id: "62a36b189d7f5a3bba723056",
      shippingAddress: {
        addressName: "",
        country: "us",
        _id: "62a37c3b4ce2163bce198b90",
        city: ".",
        outNumber: "",
        postalCode: "58070",
        state: adress,
        street: "",
        suburb: "",
        name: name,
        surname: "",
        email: "cefuentes@me.com",
        birthDay: null,
        gender: "",
        startAddress: 0,
        phone: "4431144033",
        business: "",
      },
      shippingMethod: {
        _id: "62a631abc8ab8a66986c7fc7",
        type: {
          displayName: "Unique price",
          name: "uniquePrice",
          showApiKeys: false,
          automated: false,
        },
        rules: {
          exclude: [],
          weight: [],
          totalPrice: [],
          zipCode: [],
          prodQty: [],
          virtualCart: [],
          brandQty: [],
        },
        extendedZone: { active: false, carrier: "dhl", price: 0 },
        price: 0,
        appliesTo: ["eshop", "retailer"],
        isT1Envios: false,
        isSubscription: false,
        collectionName: "shippingMethod",
        createdAt: 1655058859683,
        storesAddress: [],
        name: "T1 Envíos - USA",
        description: "",
        instructions: "",
        availability: "mx",
        code: 1,
        __v: 0,
        id: "62a631abc8ab8a66986c7fc7",
      },
    });

    fetch("https://easystreet.t1paginas.com/api/eshop/cart/createOrdenPaypal", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        const actualizarOrden = order.map((p) =>
          p.orderNumber === idOrden ? { ...p, t1Id: data.addInfo.orden } : p
        );
        setOrder(actualizarOrden);
        setSeleccionados([]);
        setid(data.addInfo.orden);
        console.log("data.addInfo.orden", data.addInfo.orden);
        await sleep(1000);
        openModal(data.addInfo.orden);
        setTimeout(() => {
          fetchApiPaso3(data.addInfo.orden, idOrden, adress, nameProduct, name);
        }, 120000);
        console.log(data);
      })
      .catch((err) => {
        swal({
          title: "Invalid User",
          text: "Not log User in T1",
          icon: "error",
          button: "OK",
        });
        console.log("********");
        console.log(err);
        console.log("********");
      });
  };

  const fetchApiPaso3 = async (item, idOrden, adress, nameProduct, name) => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      user: `{"token":"${tokenlogin}"}`,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      customer: {
        phone: "4431144033",
        email: "cefuentes@me.com",
        card: {},
        paymentIntent: null,
        ordenPaypal: item,
        useSavedCard: false,
      },
      billingAddress: {},
      notes: nameProduct,
      birthDay: 0,
      gender: "",
      startAddress: 0,
      shippingAddress: {
        addressName: "",
        country: "us",
        _id: "62a37c3b4ce2163bce198b90",
        city: ".",
        outNumber: "",
        postalCode: "",
        state: adress,
        street: "",
        suburb: "",
        name: name,
        surname: "",
        email: "cefuentes@me.com",
        birthDay: null,
        gender: "",
        startAddress: 0,
        phone: "4431144033",
        business: "",
        codeState: "30",
      },
      monthlyPays: 1,
      paymethod: "PayPal",
      paymethod_id: "62a36b189d7f5a3bba723056",
      shippingMethod: {
        _id: "62a631abc8ab8a66986c7fc7",
        type: {
          displayName: "Unique price",
          name: "uniquePrice",
          showApiKeys: false,
          automated: false,
        },
        rules: {
          exclude: [],
          weight: [],
          totalPrice: [],
          zipCode: [],
          prodQty: [],
          virtualCart: [],
          brandQty: [],
        },
        extendedZone: { active: false, carrier: "dhl", price: 0 },
        price: 0,
        appliesTo: ["eshop", "retailer"],
        isT1Envios: false,
        isSubscription: false,
        collectionName: "shippingMethod",
        createdAt: 1655058859683,
        storesAddress: [],
        name: "T1 Envíos - USA",
        description: "",
        instructions: "",
        availability: "mx",
        code: 1,
        __v: 0,
        id: "62a631abc8ab8a66986c7fc7",
      },
      paymentTerm: null,
      isSubscription: false,
    });

    const actualizarOrden = order.map((p) =>
      p.orderNumber === idOrden ? { ...p, pay: true } : p
    );
    setOrder(actualizarOrden);
    setSeleccionados([]);

    alert("Success");

    return false;

    fetch("https://easystreet.t1paginas.com/api/eshop/cart/checkout", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        if (
          data.data.message.substr(0, 38) ===
          "No se pudo procesar el pago con PayPal"
        ) {
          swal({
            title: "Pay error",
            text: "PayPal can´t process the payment",
            icon: "warning",
            buttons: ["Accept", "Go to Pay"],
          }).then((res) => {
            if (res) {
              setid(item);
              openModal(item);
            }
          });
        } else if (
          data.data.message.substr(0, 38) === "No se pudo procesar el pago con"
        ) {
          swal({
            title: "Pay error",
            text: "PayPal can´t process the payment",
            icon: "warning",
            buttons: ["Accept", "Go to Pay"],
          }).then((res) => {
            if (res) {
              setid(item);
              openModal(item);
            }
          });
        } else {
          console.log(res);
          if (data.type === 1) {
            swal({
              title: "Sent to T1",
              icon: "success",
            }).then((res) => {
              if (res) {
                const actualizarOrden = order.map((p) =>
                  p.orderNumber === idOrden ? { ...p, pay: true } : p
                );
                setOrder(actualizarOrden);
                setSeleccionados([]);
              } else {
                const actualizarOrden = order.map((p) =>
                  p.orderNumber === idOrden ? { ...p, pay: true } : p
                );
                setOrder(actualizarOrden);
                setSeleccionados([]);
              }
            });
          } else {
            swal({
              title: "Error",
              text: data.data.message,
              icon: "error",
              button: "Aceptar",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchApiPaso3T1 = async (item, idOrden, adress, nameProduct, name) => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      user: `{"token":"${tokenlogin}"}`,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      customer: {
        phone: "4431144033",
        email: "cefuentes@me.com",
        card: {},
        paymentIntent: null,
        ordenPaypal: item,
        useSavedCard: false,
      },
      billingAddress: {},
      notes: nameProduct,
      birthDay: 0,
      gender: "",
      startAddress: 0,
      shippingAddress: {
        addressName: "",
        country: "us",
        _id: "62a37c3b4ce2163bce198b90",
        city: ".",
        outNumber: "",
        postalCode: "",
        state: adress,
        street: "",
        suburb: "",
        name: name,
        surname: "",
        email: "cefuentes@me.com",
        birthDay: null,
        gender: "",
        startAddress: 0,
        phone: "4431144033",
        business: "",
        codeState: "30",
      },
      monthlyPays: 1,
      paymethod: "PayPal",
      paymethod_id: "62a36b189d7f5a3bba723056",
      shippingMethod: {
        _id: "62a631abc8ab8a66986c7fc7",
        type: {
          displayName: "Unique price",
          name: "uniquePrice",
          showApiKeys: false,
          automated: false,
        },
        rules: {
          exclude: [],
          weight: [],
          totalPrice: [],
          zipCode: [],
          prodQty: [],
          virtualCart: [],
          brandQty: [],
        },
        extendedZone: { active: false, carrier: "dhl", price: 0 },
        price: 0,
        appliesTo: ["eshop", "retailer"],
        isT1Envios: false,
        isSubscription: false,
        collectionName: "shippingMethod",
        createdAt: 1655058859683,
        storesAddress: [],
        name: "T1 Envíos - USA",
        description: "",
        instructions: "",
        availability: "mx",
        code: 1,
        __v: 0,
        id: "62a631abc8ab8a66986c7fc7",
      },
      paymentTerm: null,
      isSubscription: false,
    });

    fetch("https://easystreet.t1paginas.com/api/eshop/cart/checkout", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        if (
          data.data.message.substr(0, 38) ===
          "No se pudo procesar el pago con PayPal"
        ) {
          swal({
            title: "Pay error",
            text: "PayPal can´t process the payment",
            icon: "warning",
            buttons: ["Accept", "Go to Pay"],
          }).then((res) => {
            if (res) {
              setid(item);
              openModal(item);
            }
          });
        } else if (
          data.data.message.substr(0, 38) === "No se pudo procesar el pago con"
        ) {
          swal({
            title: "Pay error",
            text: "PayPal can´t process the payment",
            icon: "warning",
            buttons: ["Accept", "Go to Pay"],
          }).then((res) => {
            if (res) {
              setid(item);
              openModal(item);
            }
          });
        } else {
          console.log(res);
          if (data.type === 1) {
            swal({
              title: "Sent to T1",
              icon: "success",
            }).then((res) => {
              if (res) {
                const actualizarOrden = order.map((p) =>
                  p.orderNumber === idOrden ? { ...p, pay: true } : p
                );
                setOrder(actualizarOrden);
                setSeleccionados([]);
              } else {
                const actualizarOrden = order.map((p) =>
                  p.orderNumber === idOrden ? { ...p, pay: true } : p
                );
                setOrder(actualizarOrden);
                setSeleccionados([]);
              }
            });
          } else {
            swal({
              title: "Error",
              text: data.data.message,
              icon: "error",
              button: "Aceptar",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handledClick = (idOrden, adress, idProduct, nameProduct, name) => {
    fetchApiPaso1(idProduct);
    setTimeout(() => {
      fetchApiPaso2(idOrden, adress, nameProduct, name);
    }, 500);
  };

  // const ActionsButtons = (p) => {
  //   return (
  //     <div>
  //       {p.data.t1Id === "" ? (
  //         <button
  //           type="button"
  //           className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
  //           onClick={() => {
  //             fetchapidelete(
  //               p.data.orderNumber,
  //               p.data.adress,
  //               p.data.id,
  //               p.data.product,
  //               p.data.name
  //             );
  //           }}
  //           style={{
  //             width: 120,
  //           }}
  //         >
  //           Pagar en T1
  //         </button>
  //       ) : (
  //         <>
  //           {p.data.pay === true ? (
  //             <button
  //               type="button"
  //               className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1 shadow-lg"
  //               style={{
  //                 width: 120,
  //               }}
  //             >
  //               Enviado a T1
  //             </button>
  //           ) : (
  //             <>
  //               <button
  //                 type="button"
  //                 className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-2 shadow-lg"
  //                 onClick={() =>
  //                   fetchApiPaso3(
  //                     p.data.t1Id,
  //                     p.data.orderNumber,
  //                     p.data.adress,
  //                     p.data.product
  //                   )
  //                 }
  //                 style={{
  //                   width: 120,
  //                 }}
  //               >
  //                 Enviar a t1
  //               </button>
  //             </>
  //           )}
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  // const ActionEdi = (p) => {
  //   return (
  //     <ModalEdi
  //       shop={p.data.store}
  //       name={p.data.name}
  //       adress={p.data.adress}
  //       product={p.data.product}
  //     ></ModalEdi>
  //   );
  // };

  const [columnDefs] = useState([
    // {
    //   field: "Seleccionar",
    //   headerName: "Seleccionar",
    //   checkboxSelection: true,
    // },
    // {
    //   field: "orderNumber",
    //   headerName: "Orden",
    //   filter: true,
    //   sortable: true,
    //   resizable: true,
    //   floatingFilter: true,
    //   enableRowGroup: true,
    //   width: 120,
    // },
    {
      field: "store",
      headerName: "Marketplace",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      enableRowGroup: true,
      width: 140,
      checkboxSelection: true,
    },
    {
      field: "dateOrder",
      headerName: "Date",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      valueFormatter: currencyFormatter,
      enableRowGroup: true,
      width: 120,
    },
    {
      field: "name",
      headerName: "Client",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      enableRowGroup: true,
      width: 120,
    },

    {
      field: "adress",
      headerName: "Address",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      enableRowGroup: true,
      width: 130,
    },
    {
      field: "product",
      headerName: "Product",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      enableRowGroup: true,
      width: 120,
    },

    // {
    //   field: "Acciones",
    //   headerName: "Acciones",
    //   filter: true,
    //   sortable: true,
    //   cellRendererFramework: ActionsButtons,
    // },
    // {
    //   field: "EDI",
    //   headerName: "EDI",
    //   filter: true,
    //   sortable: true,
    //   cellRendererFramework: ActionEdi,
    // },
  ]);

  function currencyFormatter(params) {
    return moment(params.value).format("L");
  }

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedRows();
    // var rowCount = event.api.getSelectedNodes();
    setSeleccionados(rowCount);

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="mb-4">
        <h1 className="text-3xl font-bold text-[#4C4C4C]">Orders</h1>
      </div>
      <div className="justify-between flex text-center">
        <div></div>
        {size(seleccionados) > 0 && (
          <div className="flex flex-row">
            {seleccionados.map((item, i) => (
              <div key={i}>
                {item.t1Id === "" ? (
                  <button
                    type="button"
                    className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1 mr-4"
                    onClick={() => {
                      fetchapidelete(
                        item.orderNumber,
                        item.adress,
                        item.id,
                        item.product,
                        item.name
                      );
                    }}
                  >
                    Pagar en PayPal
                  </button>
                ) : (
                  <>
                    {item.pay === true ? (
                      <button
                        type="button"
                        className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1 mr-4"
                        style={{
                          width: 130,
                        }}
                      >
                        Sent to T1
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1 mr-4"
                          onClick={() =>
                            fetchApiPaso3(
                              item.t1Id,
                              item.orderNumber,
                              item.adress,
                              item.product,
                              item.name
                            )
                          }
                          style={{
                            width: 130,
                          }}
                        >
                          Send to T1
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
            {seleccionados.map((item, i) => (
              <div key={i} className="mr-4">
                <ModalEdi
                  shop={item.store}
                  name={item.name}
                  adress={item.adress}
                  product={item.product}
                ></ModalEdi>
              </div>
            ))}
            {seleccionados.map((item, i) => (
              <div key={i}>
                <ModalInfo
                  shop={item.store}
                  name={item.name}
                  adress={item.adress}
                  product={item.product}
                  id={item.id}
                ></ModalInfo>
              </div>
            ))}
          </div>
        )}
      </div>
      {!loading ? (
        <div className="ag-theme-alpine mb-4" style={{ height: 450 }}>
          <AgGridReact
            rowData={order}
            columnDefs={columnDefs}
            pagination={true}
            ref={gridRef}
            rowSelection={"single"}
            // rowHeight={55}
            onSelectionChanged={onSelectionChanged}
            suppressMenuHide={true}
            rowGroupPanelShow={"always"}
          ></AgGridReact>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-10">
          <ClipLoader />
        </div>
      )}
      {!loading && (
        <div className=" justify-between flex text-center">
          <div></div>

          <div
            className="text-gray-300 cursor-pointer"
            onClick={() => setOrder([])}
          >
            Clear Demo
          </div>
        </div>
      )}
      {isOpen && (
        <>
          {/* Main modal */}
          <div
            id="defaultModal"
            tabIndex={-1}
            className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
            aria-modal="true"
            role="dialog"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="relative p-4 w-full max-w-2xl h-full md:h-auto"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {/* Modal content */}
              <div
                className="relative bg-white rounded-lg shadow dark:bg-gray-700"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* Modal header */}
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={closeModal}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* Modal body */}
                <iframe
                  title="Paypal"
                  src={`https://www.paypal.com/checkoutnow?token=${id}`}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {/* {size(order) > 0 ? (
        <>
          {order.map((item, i) => (

            <li className="py-3 sm:py-4" style={{ listStyle: "none" }} key={i}>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <img
                    src={item.productPhoto}
                    className="w-12 h-12 rounded-full"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                    {item.store} - {item.product}
                  </p>
                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Orden: {item.orderNumber} / Fecha:{item.dateOrder}
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400 font-bold ">
                    Cliente: {item.name}
                  </p>
                  <p className="text-sm text-gray-500 truncate dark:text-gray-400 font-bold ">
                    Dirección: {item.adress}
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  $10.00
                </div>
              </div>
              <div className="ml-16">
                {item.t1Id === "" ? (
                  <button
                    type="button"
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 "
                    onClick={() => {
                      fetchapidelete(
                        item.orderNumber,
                        item.adress,
                        item.id,
                        item.product
                      );
                    }}
                  >
                    Pagar en T1
                  </button>
                ) : (
                  <>
                    {item.pay === true ? (
                      <button
                        type="button"
                        className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                      >
                        Enviado a T1
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                          onClick={() =>
                            fetchApiPaso3(
                              item.t1Id,
                              item.orderNumber,
                              item.adress,
                              item.product
                            )
                          }
                        >
                          Enviar a t1
                        </button>
                    
                      </>
                    )}
                  </>
                )}

                <ModalEdi></ModalEdi>
              </div>
            </li>
          ))}
        </>
      ) : (
        <div className=" h-20 rounded-xl justify-center flex flex-col items-center p-1">
          <div>{`:(`}</div>
          <h4 className="text-center text-xs">No existen ordenes pendientes</h4>
        </div>
      )} */}
    </div>
  );
}

export default Dashboard;
