import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { size } from "lodash";
import swal from "sweetalert";
import LayoutWidget from "./LayoutWidget";
import { UseLocalStorage } from "./hooks/UseLocalStorage";
import ClipLoader from "react-spinners/ClipLoader";

function Products() {
  const [order, setOrder] = UseLocalStorage("orderhts", "");
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);
  const [infoUpdate, setinfoUpdate] = useState({});
  const [clasificacion, setclasificacion] = useState("");
  const [inputOpen, setInputOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const gridRef = useRef(null);
  const timestamp = Date.now();
  var informacionOrden = {};
  let currentCategory = [];

  useEffect(() => {
    // fetchApiLogin();
    // fetchApi();
    setTimeout(() => {
      fetchApiLogin();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApiLogin = async () => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      info: "U2FsdGVkX18TF+R2rNdPmhz6SzkrAsnAInqrDOnysqekzIpMeoxs7cOhQ+jBkKWZkTswC1+Q4VYbtxGJHl/tHSGs4WsGx6fbUSNvtDQgMgU=",
    });

    fetch("https://easystreet.t1paginas.com/api/eshop/login", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        console.log(data.data.message);
        if (data.data.user.token) {
          fetchApi(data.data.user.token);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const ActionsButtons = (p) => {
  //   return (
  //     <button
  //       type="button"
  //       className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
  //       onClick={() => {
  //         setopen(true);
  //         setTimeout(() => {
  //           init();
  //           classify(p.data.description);
  //         }, 1000);
  //         fetchApiUpdate();
  //         console.log(infoUpdate._id);
  //       }}
  //       style={{
  //         width: 120,
  //       }}
  //     >
  //       Clasificar
  //     </button>
  //   );
  // };

  const fetchApiUpdate = async (code) => {
    let headersList = {
      timestamp: timestamp,
      user: "62a36b189d7f5a3bba723079",
    };

    let bodyContent = JSON.stringify({
      satInformation: "",
      rating: infoUpdate.rating,
      metadata: infoUpdate.metadata,
      dimensions: infoUpdate.dimensions,
      brand: null,
      lastedit: infoUpdate.lastedit,
      barcode: infoUpdate.barcode,
      images: infoUpdate.images,
      usesInventory: infoUpdate.usesInventory,
      invMinQty: infoUpdate.invMinQty,
      invMaxQty: infoUpdate.invMaxQty,
      vat: infoUpdate.vat,
      hasVat: infoUpdate.hasVat,
      buyCurrency: infoUpdate.buyCurrency,
      sellCurrency: infoUpdate.sellCurrency,
      uPrice: infoUpdate.uPrice,
      buyPrice: infoUpdate.buyPrice,
      pointsPrice: infoUpdate.pointsPrice,
      tags: infoUpdate.tags,
      providers: infoUpdate.providers,
      categories: infoUpdate.categories,
      wholesale: infoUpdate.wholesale,
      weight: infoUpdate.weight,
      isPackage: infoUpdate.isPackage,
      isDefinedPackage: infoUpdate.isDefinedPackage,
      unit: infoUpdate.unit,
      hasIeps: infoUpdate.hasIeps,
      ieps: infoUpdate.ieps,
      appliesTo: infoUpdate.appliesTo,
      pFactor: infoUpdate.pFactor,
      cFactor: infoUpdate.cFactor,
      factors: infoUpdate.factors,
      consumible: infoUpdate.consumible,
      isDeleted: infoUpdate.isDeleted,
      collectionName: infoUpdate.collectionName,
      createdAt: infoUpdate.createdAt,
      enabled: infoUpdate.enabled,
      _id: infoUpdate._id,
      extraInformation: infoUpdate.extraInformation,
      media: infoUpdate.media,
      prices: infoUpdate.prices,
      variations: infoUpdate.variations,
      uses: infoUpdate.uses,
      integrations: infoUpdate.integrations,
      name: infoUpdate.name,
      code: infoUpdate.code,
      __v: infoUpdate.__v,
      qty: 993,
      disabledConfirmation: true,
      utilityPrice: 10,
      utility: 100,
      model: code,
    });

    fetch(
      `https://easystreet.t1paginas.com/api/products/id/${infoUpdate._id}`,
      {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      }
    )
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        console.log(data);
        swal({
          title: "Actualización exitosa",
          text: data.data.message,
          icon: "success",
        }).then((res) => {
          if (res) {
            setopen(false);
            setInputOpen(false);
            window.location.reload();
          } else {
            setopen(false);
            setInputOpen(false);
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedRows();
    const {
      rating,
      metadata,
      dimensions,
      lastedit,
      barcode,
      images,
      usesInventory,
      invMinQty,
      invMaxQty,
      vat,
      hasVat,
      buyCurrency,
      sellCurrency,
      uPrice,
      buyPrice,
      pointsPrice,
      tags,
      providers,
      categories,
      wholesale,
      weight,
      isPackage,
      isDefinedPackage,
      unit,
      hasIeps,
      ieps,
      appliesTo,
      pFactor,
      cFactor,
      factors,
      consumible,
      isDeleted,
      collectionName,
      createdAt,
      enabled,
      _id,
      extraInformation,
      media,
      prices,
      variations,
      uses,
      integrations,
      name,
      code,
      __v,
    } = rowCount[0] ? rowCount[0] : {};
    setSeleccionados(rowCount);
    setinfoUpdate({
      rating,
      metadata,
      dimensions,
      lastedit,
      barcode,
      images,
      usesInventory,
      invMinQty,
      invMaxQty,
      vat,
      hasVat,
      buyCurrency,
      sellCurrency,
      uPrice,
      buyPrice,
      pointsPrice,
      tags,
      providers,
      categories,
      wholesale,
      weight,
      isPackage,
      isDefinedPackage,
      unit,
      hasIeps,
      ieps,
      appliesTo,
      pFactor,
      cFactor,
      factors,
      consumible,
      isDeleted,
      collectionName,
      createdAt,
      enabled,
      _id,
      extraInformation,
      media,
      prices,
      variations,
      uses,
      integrations,
      name,
      code,
      __v,
    });
    // eslint-disable-next-line
  }, []);

  const HtsFuncion = (p) => {
    let currentCategory = [];

    currentCategory =
      order && order.filter((order) => order.productIdt1 === p.data._id);
    return (
      <>
        {size(currentCategory) > 0 ? (
          <div>
            {currentCategory.map((item, i) => (
              <div key={i}>{item.hts}</div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </>
    );
  };

  const ClasificarFuncion = () => {
    let currentCategory = [];

    if (size(seleccionados) === 1) {
      currentCategory =
        order &&
        order.filter((order) => order.productIdt1 === seleccionados[0]._id);
    }
    if (size(currentCategory) > 0) {
      return (
        <button
          type="button"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            swal({
              title: "Este producto ya ha sido clasificado",
              text: currentCategory[0].hts,
              icon: "success",
            }).then((res) => {
              console.log(res);
            });
          }}
          style={{
            width: 120,
          }}
        >
          Clasificado
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
          onClick={() => {
            setopen(true);
            setInputOpen(true);
            setTimeout(() => {
              init();
              classify(seleccionados[0].description);
            }, 500);
          }}
          style={{
            width: 120,
          }}
        >
          Sort
        </button>
      );
    }
  };

  const [columnDefs] = useState([
    // {
    //   field: "Seleccionar",
    //   headerName: "Seleccionar",
    //   checkboxSelection: true,
    // },
    {
      field: "name",
      headerName: "Product",
      filter: true,
      sortable: true,
      // cellStyle: () => ({
      //   display: "flex",
      //   alignItems: "center",
      // }),
      resizable: true,
      floatingFilter: true,
      checkboxSelection: true,
      width: 200,
    },
    {
      field: "uPrice",
      headerName: "Price",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      width: 100,
      cellStyle: () => ({
        display: "flex",
        alignItems: "right",
        justifyContent: "right",
      }),
      valueFormatter: currencyFormatter,
    },
    // {
    //   field: "quantity",
    //   headerName: "Inventario",
    //   filter: true,
    //   sortable: true,
    //   resizable: true,
    //   floatingFilter: true,
    //   width: 130,
    // },
    // {
    //   field: "_id",
    //   headerName: "Id",
    //   filter: true,
    //   sortable: true,
    //   resizable: true,
    //   floatingFilter: true,
    //   width: 100,
    // },
    {
      headerName: "HTS",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      width: 130,
      cellRenderer: HtsFuncion,
    },
    // {
    //   field: "Acciones",
    //   headerName: "Acciones",
    //   resizable: true,
    //   cellRendererFramework: ActionsButtons,
    // },
  ]);

  function currencyFormatter(params) {
    return params.value.toFixed(2);
  }

  const fetchApi = async (token) => {
    let headersList = {
      Accept: "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      user: `{"token": "${token}"}`,
      "Content-Type": "application/json",
    };
    fetch(
      "https://easystreet.t1paginas.com/api/eshop/products/?&per_page=16&page=1",
      {
        method: "GET",
        headers: headersList,
      }
    )
      .then(async (res) => {
        const responeJSON = await res.json();
        const informacion = responeJSON.data;
        if (!informacion.message) {
          currentCategory = informacion.filter((res) => res.quantity !== 0);
          setData(currentCategory);
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function classify(info) {
    window.ccce.classify(
      {
        product: clasificacion ? clasificacion : info,
        destination: "US",
        origin: "MX",
        lang: "ES",
        useKeyboard: true,
        hs6: false,
      },
      myCallback,
      myAbortFunction
    );
  }

  function init() {
    const el = document.querySelector("script[data-element-id]");
    window.ccce.init(el);
  }

  function myCallback(results) {
    fetchApiUpdate(results.hsCode);
    console.log("*********");
    console.log(results);
    console.log("*********");

    if (size(order) > 0) {
      informacionOrden = [
        ...order,
        {
          productIdt1: seleccionados[0]._id,
          hts: results.hsCode,
        },
      ];
      setOrder(informacionOrden);
    } else {
      informacionOrden = [
        {
          productIdt1: seleccionados[0]._id,
          hts: results.hsCode,
        },
      ];
      setOrder(informacionOrden);
    }
  }
  function myAbortFunction(results) {
    console.log("user aborted classification");
    // const iframe = document.getElementById("ccce-classyvue");
    // iframe.src = "";
    setopen(false);
    setInputOpen(false);
    window.location.reload();
  }

  const limpiarDemo = () => {
    setOrder([]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <>
      {!inputOpen && (
        <div>
          <div className="mb-4">
            <h1 className="text-3xl font-bold text-[#4C4C4C]">Products</h1>
          </div>
        </div>
      )}
      {!inputOpen && (
        <div className="justify-between flex text-center items-center">
          {size(seleccionados) === 1 && (
            <div>
              {seleccionados.map((item, i) => (
                <input
                  key={i}
                  className="w-full"
                  onChange={(e) => setclasificacion(e.target.value)}
                  name="name"
                  type="text"
                  placeholder={item.description}
                  style={{
                    borderWidth: 1,
                    padding: 5,
                    borderRadius: 4,
                    marginBottom: 2,
                  }}
                />
              ))}
            </div>
          )}
          {/* {size(seleccionados) === 1 && (
            <div>
              {seleccionados.map((item, i) => (
                <div key={i}>
                  <button
                    type="button"
                    className="text-white bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
                    onClick={() => {
                      setopen(true);
                      setInputOpen(true);
                      setidProducto(item._id);
                      setTimeout(() => {
                        init();
                        classify(item.description);
                      }, 500);
                    }}
                    style={{
                      width: 120,
                    }}
                  >
                    Clasificar
                  </button>
                </div>
              ))}
            </div>
          )} */}
          {size(seleccionados) === 1 && ClasificarFuncion()}
          {/* {ClasificarFuncion()} */}
        </div>
      )}
      {inputOpen && (
        <div className="flex flex-row">
          <input
            className="w-full"
            onChange={(e) => setclasificacion(e.target.value)}
            name="name"
            type="text"
            placeholder="Description"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
              width: "50%",
              marginRight: 10,
            }}
          />
          <button
            type="button"
            className="text-white bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
            onClick={() => {
              setopen(true);
              setInputOpen(true);
              setTimeout(() => {
                init();
                classify(clasificacion);
              }, 500);
            }}
            style={{
              width: 120,
            }}
          >
            Search
          </button>
        </div>
      )}
      {inputOpen && <LayoutWidget />}
      {open && (
        <div
          id="my-div-clasificate"
          style={{
            position: "absolute",
            zindex: 6000,
            borderRadius: 0,
            maxWidth: "100vw",
            maxHeight: "100vh ",
            top: 170,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        ></div>
      )}
      {!inputOpen && (
        <>
          {!loading ? (
            <div className="ag-theme-alpine mb-4" style={{ height: 450 }}>
              <AgGridReact
                rowData={data}
                columnDefs={columnDefs}
                pagination={true}
                ref={gridRef}
                rowSelection={"single"}
                // rowHeight={55}
                onSelectionChanged={onSelectionChanged}
                suppressMenuHide={true}
                rowGroupPanelShow={"always"}
              ></AgGridReact>
            </div>
          ) : (
            <div className="flex items-center justify-center mt-10">
              <ClipLoader />
            </div>
          )}
        </>
      )}
      {!loading && (
        <div className=" justify-between flex text-center">
          <div></div>

          <div className="text-gray-300 cursor-pointer" onClick={limpiarDemo}>
            Clear Demo
          </div>
        </div>
      )}
    </>
  );
}

export default Products;
