import React from "react";
import AceEditor from "react-ace";

function Type86(props) {
  const { customer, shippingAddress_obj, notes, name, billing_address } =
    props.data[0];
  const address = shippingAddress_obj
    ? shippingAddress_obj.state.split(",")
    : "";
  const addressPlus = address ? address[2]?.split(" ") : "";

  const data = `
A1001S4GCUSCTY090622 SE CCP0000023363
B 2704EJ8SE CUSTOMSCITY
SE10AEJ8 20183856 86EI 33-06012220040000000000252720
SE13DARREN ZHANG 5108962805
SE15M 11265930023
SE15H 2399212084 00000001
SE30SE ${customer.name ? customer.name : customer.first_name}
SE3515HUICHENG ROAD XINHUI DISTRICT JIA
SE36JIANGMEN CN
SE30CN ${customer.name ? customer.name : customer.first_name}
SE35155765 ELDORA DRIVE
SE36COLORADO SPRINGS CO 80918 US
SE40001CN ${notes ? notes : name}
SE6096159020000000000025
SE10AEJ8 20184060 86EI 33-06012220040000000000282720
SE13DARREN ZHANG 5108962805
SE15M 11265930023
SE15H 6579271663 00000001
SE30SE ${customer.name ? customer.name : customer.first_name}
SE3515HUICHENG ROAD XINHUI DISTRICT JIA
SE36JIANGMEN CN
SE30CN Carlos Fuentes
SE351511738 ${address[0] ? address[0] : billing_address.address1}
SE36 ${address[1] ? address[1] : billing_address.city}  ${
    addressPlus[1] ? addressPlus[1] : billing_address.province_code
  }       ${
    addressPlus[2] ? addressPlus[2] : billing_address.zip
  }          US       
Y 2704EJ8SE
Z1001S4GCUSCTY090622
  `;

  return (
    <div
      className="mt-2 w-full h-full text-justify pb-10 pr-10 pl-10"
      style={{
        overflowY: "scroll",
        wordWrap: "break-word",
        marginBottom: 20,
      }}
    >
      <AceEditor
        width="100%"
        height="100%"
        mode="javascript"
        theme="tomorrow"
        name="editor"
        value={data}
        fontSize={14}
        editorProps={{ $blockScrolling: true }}
      />
      {/* <div>{`A1001S4GCUSCTY090622     SE                                        CCP0000023363`}</div>
      <div>{`B  2704EJ8SE                                                         CUSTOMSCITY`}</div>
      <div>{`SE10AEJ8  20183856 86EI 33-06012220040000000000252720   `}</div>
      <div>{`SE13DARREN ZHANG                            5108962805   `}</div>
      <div>{`SE15M    11265930023   `}</div>
      <div>{`SE15H    2399212084                                        00000001       `}</div>
      <div>{`SE30SE ${
        customer.name ? customer.name : customer.first_name
      }    `}</div>
      <div>{`SE3515HUICHENG ROAD XINHUI DISTRICT JIA `}</div>
      <div>{`SE36JIANGMEN                                                   CN              `}</div>
      <div>{`SE30CN ${
        customer.name ? customer.name : customer.first_name
      }      `}</div>
      <div>{`SE35155765 ELDORA DRIVE  `}</div>
      <div>{`SE36COLORADO SPRINGS                   CO       80918          US    `}</div>
      <div>{`SE40001CN ${notes ? notes : name} `}</div>
      <div>{`SE6096159020000000000025 `}</div>
      <div>{`SE10AEJ8  20184060 86EI 33-06012220040000000000282720  `}</div>
      <div>{`SE13DARREN ZHANG                            5108962805 `}</div>
      <div>{`SE15M    11265930023  `}</div>
      <div>{`SE15H    6579271663                                        00000001 `}</div>
      <div>{`SE30SE ${
        customer.name ? customer.name : customer.first_name
      } `}</div>
      <div>{`SE3515HUICHENG ROAD XINHUI DISTRICT JIA                `}</div>
      <div>{`SE36JIANGMEN                                                   CN   `}</div>
      <div>{`SE30CN ${
        customer.name ? customer.name : customer.first_name
      }`}</div>
      <div>{`SE351511738 ${
        address[0] ? address[0] : billing_address.address1
      }  `}</div>
      <div>{`SE36${
        address[1] ? address[1] : billing_address.city
      }                        ${
        addressPlus[1] ? addressPlus[1] : billing_address.province_code
      }       ${
        addressPlus[2] ? addressPlus[2] : billing_address.zip
      }          US`}</div>
      <div>{`Y  2704EJ8SE `}</div>
      <div>{`Z1001S4GCUSCTY090622  `}</div> */}
    </div>
  );
}

export default Type86;
