import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AmazonShop from "./components/AmazonShop";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import LayoutDashboard from "./components/LayoutDashboard";
import NoMatch from "./components/NoMatch";
import PedidosEntregas from "./components/PedidosEntregas";
import Home from "./components/Home";
import CheckoutAmazon from "./components/CheckoutAmazon";
import TargetShop from "./components/TargetShop";
import CheckoutTarget from "./components/CheckoutTarget";
import WalmartShop from "./components/WalmartShop";
import CheckoutWalmart from "./components/CheckoutWalmart";
import Products from "./components/Products";
import EntregasShopify from "./components/EntregasShopify";
import ProductsShopify from "./components/ProductsShopify";
//import PdfParse from "./components/PdfParse";
//import ImageGenerator from './components/ImageGenerator';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutDashboard />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/dashboard" element={<LayoutDashboard />}>
          <Route index element={<Dashboard />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/pedidosyentregas" element={<LayoutDashboard />}>
          <Route index element={<PedidosEntregas />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/amazonshop" element={<Layout />}>
          <Route index element={<AmazonShop />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/checkoutamazon" element={<Layout />}>
          <Route index element={<CheckoutAmazon />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/targetshop" element={<Layout />}>
          <Route index element={<TargetShop />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/checkouttarget" element={<Layout />}>
          <Route index element={<CheckoutTarget />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/walmartshop" element={<Layout />}>
          <Route index element={<WalmartShop />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/checkoutwalmart" element={<Layout />}>
          <Route index element={<CheckoutWalmart />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/products" element={<LayoutDashboard />}>
          <Route index element={<Products />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/entregasshopify" element={<LayoutDashboard />}>
          <Route index element={<EntregasShopify />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/productosshopify" element={<LayoutDashboard />}>
          <Route index element={<ProductsShopify />} />
          {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
        {/*<Route path="/pdfparse" element={<LayoutDashboard />}>*/}
        {/*<Route index element={<PdfParse />} />*/}
        {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
        {/*<Route path="*" element={<NoMatch />} />*/}
        {/*</Routes>*/}
        {/*<Route path="/imagegenerator" element={<LayoutDashboard />}>*/}
        {/*<Route index element={<ImageGenerator />} />*/}
        {/* <Route path="detalle" element={<div>Detalle de la orden</div>} /> */}
        {/*<Route path="*" element={<NoMatch />} />*/}
        {/*</Route>*/}
      </Routes>
    </>
  );
}

export default App;
