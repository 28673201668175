import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ModalEnvio from "../components/ModalEnvio";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { size } from "lodash";
import DetailFunctionOrder from "./DetailFunctionOrder";
import { UseLocalStorage } from "./hooks/UseLocalStorage";
// import { Dialog, Transition } from "@headlessui/react";
// import { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";
// import { Fragment } from "react";
// import { Menu, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/solid";
// import BeatLoader from "react-spinners/BeatLoader";

function PedidosEntregas() {
  const [order, setOrder] = UseLocalStorage("orderusd", "");
  // const [label, setlabel] = useState("");
  // const [openLabel, setopenLabel] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);
  const timestamp = Date.now();
  const gridRef = useRef(null);
  // var informacionOrden = {};

  const detailCellRenderer = useMemo(() => {
    return DetailFunctionOrder;
  }, []);

  const detailCellRendererParams = { seleccionados };

  useEffect(() => {
    setInterval(() => {
      const pedidos = JSON.parse(localStorage.getItem("orderusd"));
      if (pedidos) {
        setOrder(pedidos);
      }
    }, 1000);
    fetchApi();
    console.log(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // }, []);

  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(" ");
  // }

  const fetchApi = async () => {
    fetch(
      "https://easystreet.t1paginas.com/api/eShopOrders?&order=-createdAt&per_page=30&page=1&rangedSearch=status_0",
      {
        method: "get",
        headers: new Headers({
          user: "62a36b189d7f5a3bba723079",
          timestamp: timestamp,
        }),
      }
    )
      .then(async (res) => {
        const responeJSON = await res.json();
        const informacion = responeJSON.data;
        setData(
          informacion.sort((a, b) => {
            a = new Date(a.createdAt);
            b = new Date(b.createdAt);
            return a > b ? -1 : a < b ? 1 : 0;
          })
        );
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleChange = (e, item) => {
  //   const { name, checked } = e.target;
  //   const info = {
  //     item,
  //   };

  //   if (name === "allSelect") {
  //     let tempOrder = data.map((order) => {
  //       return { ...order, isChecked: checked };
  //     });
  //     setData(tempOrder);
  //   } else {
  //     let tempOrder = data.map((order) =>
  //       order.id === name ? { ...order, isChecked: checked } : order
  //     );
  //     console.log(tempOrder);
  //     // setData(tempOrder);
  //     if (checked === true) {
  //       if (size(seleccionados) > 0) {
  //         informacionOrden = [...seleccionados, info];
  //         setSeleccionados(informacionOrden);
  //       } else {
  //         informacionOrden = [info];
  //         setSeleccionados(informacionOrden);
  //       }
  //     } else {
  //       const info = seleccionados.filter((e) => e.item.id !== item.id);
  //       setSeleccionados(info);
  //     }
  //   }
  // };

  // const CheckboxRow = (p) => {
  //   return (
  //     <input
  //       id="bordered-checkbox-1"
  //       type="checkbox"
  //       onChange={(e) => handleChange(e, p.data)}
  //       name={p.data.id}
  //       checked={p.data?.isChecked || false}
  //       className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
  //     />
  //   );
  // };

  // const buttonActionRow = (p) => {
  //   return (
  //     <button className="cursor-pointer text-center">Imprimir Etiquetas</button>
  //   );
  // };

  const PrecioFuncion = (p) => {
    let currentCategory = [];

    currentCategory =
      order && order.filter((order) => order.idT1 === p.data.id);
    return (
      <>
        {size(currentCategory) > 0 ? (
          <div>
            {currentCategory.map((item, i) => (
              <div key={i}>
                {Number(Number(item.shipPrice) + Number(1.5)).toFixed(2)}
              </div>
            ))}
          </div>
        ) : (
          <div>0.00</div>
        )}
      </>
    );
  };

  const ShipFuncion = (p) => {
    let currentCategory = [];

    currentCategory = order && order.filter((order) => order.idT1 === p[0].id);

    if (size(currentCategory) > 0) {
      return (
        // <button
        //   type="button"
        //   className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] mr-4 py-2.5 px-5 text-sm font-medium focus:outline-none rounded-lg  focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        //   onClick={() => {
        //     setlabel(currentCategory[0].etiqueta);
        //     setTimeout(() => {
        //       setopenLabel(true);
        //     }, 100);
        //   }}
        // >
        //   Ver etiqueta
        // </button>
        <div></div>
      );
    } else {
      return <ModalEnvio seleccionados={seleccionados} country={"US"} />;
    }
  };

  const [columnDefs] = useState([
    // {
    //   field: "Seleccionar",
    //   headerName: "Seleccionar",
    //   // cellRendererFramework: CheckboxRow,
    //   // cellStyle: () => ({
    //   //   display: "flex",
    //   //   alignItems: "center",
    //   //   justifyContent: "center",
    //   // }),
    //   // headerCheckboxSelection: true,
    //   checkboxSelection: true,
    // },
    // {
    //   field: "id",
    //   headerName: "Orden",
    //   filter: true,
    //   sortable: true,
    //   resizable: true,
    //   floatingFilter: true,
    //   width: 120,
    // },
    {
      field: "notes",
      headerName: "Product",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      width: 130,
      checkboxSelection: true,
      cellRenderer: "agGroupCellRenderer",
    },

    {
      field: "customer.email",
      headerName: "Email",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      width: 120,
    },
    {
      field: "customer.name",
      headerName: "Client",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      width: 120,
    },
    {
      headerName: "Price (USD)",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      width: 150,
      cellStyle: () => ({
        display: "flex",
        alignItems: "right",
        justifyContent: "right",
      }),
      cellRenderer: PrecioFuncion,
    },
    // {
    //   field: "Imprimir Etiquetas",
    //   headerName: "Acciones",
    //   filter: true,
    //   sortable: true,
    //   cellRendererFramework: buttonActionRow,
    // },
  ]);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedRows();
    // var rowCount = event.api.getSelectedNodes();
    setSeleccionados(rowCount);
    console.log(rowCount);
  }, []);

  const limpiarDemo = () => {
    setOrder([]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  console.log("*********");
  console.log(data);
  console.log("*********");

  return (
    <>
      <div className="mb-4">
        <h1 className="text-3xl font-bold text-[#4C4C4C]">Shipments</h1>
      </div>
      <div className=" justify-between flex text-center">
        {/* <div className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
          Seleccionar todo
          <input
            id="bordered-checkbox-1"
            type="checkbox"
            name="allSelect"
            onChange={handleChange}
            checked={
              data.filter((order) => order?.isChecked !== true).length < 1
            }
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-1  "
          />
        </div> */}
        {size(seleccionados) === 0 ? (
          <div></div>
        ) : (
          <div className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-[#828282] rounded-lg border focus:z-10 focus:ring-4 flex items-center">
            {<div>{size(seleccionados)} selected</div>}
          </div>
        )}
        {size(seleccionados) > 0 && (
          <div className="mb-2 flex flex-row">
            {/* <button
              type="button"
              className="text-white bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              style={{
                width: 100,
              }}
              onClick={() =>
                window.open(
                  "https://ehub-prod.s3.amazonaws.com/public/files/label_images/20220906/9a4d206a-95f3-4112-bcf3-998673326a79.png",
                  "_blank",
                  "allow-popups"
                )
              }
            >
              Imprimir etiqueta
            </button> */}

            {/* <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="text-white bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center flex flex-row">
                  Options
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={() =>
                            window.open(
                              "https://ehub-prod.s3.amazonaws.com/public/files/label_images/20220906/9a4d206a-95f3-4112-bcf3-998673326a79.png",
                              "_blank",
                              "allow-popups"
                            )
                          }
                        >
                          Imprimir etiqueta
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={() =>
                            console.log("Factura de exportación mexicana")
                          }
                        >
                          Factura de exportación mexicana
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={() => console.log("Carta Porte")}
                        >
                          Carta Porte
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={() => console.log("Funcion 3")}
                        >
                          Truck E-Manifest
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="submit"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                          onClick={() => console.log("Funcion 3")}
                        >
                          ACAS, AIr AMS, Type 86
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <div className="w-1 "></div> */}
            {ShipFuncion(seleccionados)}
            {/* <ModalEnvio seleccionados={seleccionados} country={"US"} /> */}
            {/* <ModalEnvio seleccionados={seleccionados} country={"MX"} /> */}
          </div>
        )}
      </div>
      {!loading ? (
        <div
          className="ag-theme-alpine mb-4"
          style={{
            height: 450,
            width: window.innerWidth - "20%",
          }}
        >
          <AgGridReact
            rowData={data}
            columnDefs={columnDefs}
            pagination={true}
            ref={gridRef}
            rowSelection="single"
            onSelectionChanged={onSelectionChanged}
            suppressMenuHide={true}
            rowGroupPanelShow={"always"}
            masterDetail={true}
            detailCellRenderer={detailCellRenderer}
            detailCellRendererParams={detailCellRendererParams}
          ></AgGridReact>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-10">
          <ClipLoader />
        </div>
      )}
      {!loading && (
        <div className=" justify-between flex text-center">
          <div></div>

          <div className="text-gray-300 cursor-pointer" onClick={limpiarDemo}>
            Clear Demo
          </div>
        </div>
      )}

      {/* {!loading ? (
        <>
          {size(data) > 0 ? (
            <>
              <div className=" justify-between flex text-center">
                <div className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex items-center">
                  Seleccionar todo
                  <input
                    id="bordered-checkbox-1"
                    type="checkbox"
                    name="allSelect"
                    onChange={handleChange}
                    checked={
                      data.filter((order) => order?.isChecked !== true).length <
                      1
                    }
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-1  "
                  />
                </div>
                <ModalEnvio />
              </div>

              {window.innerWidth <= 1024 ? (
                <>
                  {data.map((item, i) => (
                    <div className="container " key={i}>
                      <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5 ">
                        <thead className="text-white">
                          <tr className="bg-slate-800 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                            <th className="p-3 text-center">Seleccionar</th>
                            <th className="p-3 text-center">Tienda</th>
                            <th className="p-3 text-center">Orden</th>
                            <th className="p-3 text-center">Correo</th>
                            <th className="p-3 text-center">Cliente</th>
                            <th className="p-3 text-center">Acciones</th>
                          </tr>
                        </thead>

                        <tbody className="flex-1 sm:flex-none">
                          <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate text-center">
                              <input
                                id="bordered-checkbox-1"
                                type="checkbox"
                                // onChange={handleChange}
                                onChange={(e) => handleChange(e, item)}
                                name={item.id}
                                checked={item?.isChecked || false}
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </td>
                            <td className="border-grey-light border hover:bg-gray-100 p-3 text-center">
                              Amazon
                            </td>
                            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate  text-center">
                              {item.id}
                            </td>
                            {item.customer && (
                              <td className="border-grey-light border hover:bg-gray-100 p-3 truncate text-center">
                                {item.customer.email}
                              </td>
                            )}
                            {item.customer && (
                              <td className="border-grey-light border hover:bg-gray-100 p-3 truncate text-center">
                                {item.customer.name}
                              </td>
                            )}
                            <td className="border-grey-light border hover:bg-gray-100 p-3 text-slate-800 hover:text-blue-600 cursor-pointer text-center">
                              Imprimir Etiquetas
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="container ">
                    <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5 ">
                      <thead className="text-white">
                        <tr
                          className=" flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                          style={{
                            backgroundColor: "#335455",
                          }}
                        >
                          <th className="p-3 text-center">Seleccionar</th>

                          <th className="p-3 text-center">Tienda</th>
                          <th className="p-3 text-center">Orden</th>
                          <th className="p-3 text-center">Correo</th>
                          <th className="p-3 text-center">Cliente</th>
                          <th className="p-3 text-center">Acciones</th>
                        </tr>
                      </thead>
                      {data.map((item, i) => (
                        <tbody className="flex-1 sm:flex-none" key={i}>
                          <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate text-center">
                              <input
                                id="bordered-checkbox-1"
                                type="checkbox"
                                onChange={(e) => handleChange(e, item)}
                                name={item.id}
                                checked={item?.isChecked || false}
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </td>
                            <td className="border-grey-light border hover:bg-gray-100 p-3 text-center">
                              Amazon
                            </td>
                            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate  text-center">
                              {item.id}
                            </td>
                            {item.customer && (
                              <td className="border-grey-light border hover:bg-gray-100 p-3 truncate text-center">
                                {item.customer.email}
                              </td>
                            )}
                            {item.customer && (
                              <td className="border-grey-light border hover:bg-gray-100 p-3 truncate text-center">
                                {item.customer.name}
                              </td>
                            )}
                            <td className="border-grey-light border hover:bg-gray-100 p-3 text-slate-800 hover:text-blue-600 cursor-pointer text-center">
                              Imprimir Etiquetas
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className=" h-20 rounded-xl justify-center flex flex-col items-center p-1">
              <div>{`:(`}</div>
              <h4 className="text-center text-xs">
                No existen ordenes pendientes
              </h4>
            </div>
          )}
        </>
      ) : (
        <div className="col-12 ">
          <div className=" h-20 rounded-xl justify-center flex flex-col items-center p-1">
            <BeatLoader color={"#335455"} size={10} className="mb-2 " />
            <h4 className="text-center text-xs">Cargando información</h4>
          </div>
        </div>
      )} */}
      {/* {openLabel && (
        <>
          <Transition show={openLabel} as={Fragment}>
            <Dialog
              as="div"
              className="w-full h-full"
              onClose={() => {
                setopenLabel(false);
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25 " />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto items-center justify-center">
                <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left shadow-xl transition-all items-center justify-center ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                      >
                        <div>Información EDI</div>
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-toggle="defaultModal"
                          onClick={() => {
                            setopenLabel(false);
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </Dialog.Title>
                      <iframe
                        title="Label"
                        className="pb-10 pr-10 pl-10"
                        src={label}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )} */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  html,\n  body {\n    height: 100%;\n  }\n\n  @media (min-width: 640px) {\n    table {\n      display: inline-table !important;\n    }\n\n    thead tr:not(:first-child) {\n      display: none;\n    }\n  }\n\n  td:not(:last-child) {\n    border-bottom: 0;\n  }\n\n  th:not(:last-child) {\n    border-bottom: 2px solid rgba(0, 0, 0, .1);\n  }\n",
        }}
      />
    </>
  );
}

export default PedidosEntregas;
