import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import EDI from "../lib/EDI";
import { size } from "lodash";

export default function ModalEdi(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState([]);
  const { shop, name, adress, product } = props;

  useEffect(() => {
    setdata(EDI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        className="mt-1 mb-2  py-2.5 px-5  text-sm font-mediumfocus:outline-none text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] rounded-lg  focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        onClick={openModal}
      >
        Ver EDI
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="w-full h-full" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                  >
                    <div>Información EDI</div>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                      onClick={closeModal}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </Dialog.Title>
                  {size(data) > 0 && (
                    <>
                      {data.map((item, i) => (
                        <div
                          key={i}
                          style={{
                            overflowY: "scroll",
                            wordWrap: "break-word",
                            marginBottom: 20,
                          }}
                          className="mt-2 w-full h-full"
                        >
                          <div>{`ISA*00*          *00*          *ZZ*${shop}        *12*8775543306     *220817*1635*U*00401*097392984*0*P*>~`}</div>
                          <div>{`GS*PO*LBMLEDI*8775543306*20220817*1635*97392985*X*004010~`}</div>
                          <div>{` ST*850*0001~`}</div>
                          <div>{` BEG*00*DS*4401237347**20220817~`}</div>
                          <div>{` REF*EM*False~`}</div>
                          <div>{` REF*CO*424498~`}</div>
                          <div>{` REF*PID*595~`}</div>
                          <div>{`DTM*010*20220819~`}</div>
                          <dvi>{` TD5*****0039~`}</dvi>
                          <div>{` N1*BT*Mission Linen Supply~`}</div>
                          <div>{` N3*${adress}~`}</div>
                          <div>{` PER*IC**TE*877-800-1603~`}</div>
                          <div>{`   N1*ST*${name}~`}</div>
                          <div>{`N2*${name}~`}</div>
                          <div>{`   N3*${adress}~`}</div>
                          <div>{`PO1*20*1*EA*50.00*TE*IN*1993932330*VN*889610269201~`}</div>
                          <div>{`    PID*F*08***${product}~`}</div>
                          <div>
                            {`PO1*10*1*EA*50.00*TE*IN*1993935080*VN*889610270221~`}
                          </div>
                          <div>
                            {` PID*F*08***PANTS NM060FREN M FRENCH BLUE~`}
                          </div>
                          <div>{` CTT*2~`}</div>
                          <div>{`   SE*21*0001~`}</div>
                          <div>{`  GE*1*97392985~`}</div>
                          <div>{`IEA*1*097392984~`}</div>

                          <div>{`ISA*00*          *00*          *ZZ*${shop}        *12*8775543306     *220817*1635*U*00401*097392984*0*P*>~`}</div>
                          <div>{`GS*PO*LBMLEDI*8775543306*20220817*1635*97392985*X*004010~`}</div>
                          <div>{` ST*850*0001~`}</div>
                          <div>{` BEG*00*DS*4401237347**20220817~`}</div>
                          <div>{` REF*EM*False~`}</div>
                          <div>{` REF*CO*424498~`}</div>
                          <div>{` REF*PID*595~`}</div>
                          <div>{`DTM*010*20220819~`}</div>
                          <dvi>{` TD5*****0039~`}</dvi>
                          <div>{` N1*BT*Mission Linen Supply~`}</div>
                          <div>{` N3*${adress}~`}</div>
                          <div>{` PER*IC**TE*877-800-1603~`}</div>
                          <div>{`   N1*ST*${name}~`}</div>
                          <div>{`N2*${name}~`}</div>
                          <div>{`   N3*${adress}~`}</div>
                          <div>{`PO1*20*1*EA*50.00*TE*IN*1993932330*VN*889610269201~`}</div>
                          <div>{`    PID*F*08***${product}~`}</div>
                          <div>
                            {`PO1*10*1*EA*50.00*TE*IN*1993935080*VN*889610270221~`}
                          </div>
                          <div>
                            {` PID*F*08***PANTS NM060FREN M FRENCH BLUE~`}
                          </div>
                          <div>{` CTT*2~`}</div>
                          <div>{`   SE*21*0001~`}</div>
                          <div>{`  GE*1*97392985~`}</div>
                          <div className="mb-10">{`IEA*1*097392984~`}</div>
                        </div>
                      ))}
                    </>
                  )}
                  {/* 
                  <div className="mt-14 ">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Aceptar
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
