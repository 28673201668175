import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { MdInfoOutline } from "react-icons/md";

export default function ModalInfo(props) {
  let [isOpen, setIsOpen] = useState(false);
  const { shop, name, adress, product, id } = props;

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        className="text-[#D93A26] hover:text-white border-2 border-[#D93A26] bg-white hover:bg-[#D93A26] mt-1 mb-2  py-2.5 px-5  text-sm font-medium  focus:outline-none rounded-lg focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        onClick={openModal}
      >
        <MdInfoOutline size={20} />
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="w-full h-full" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                  >
                    <div>Información pedido</div>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                      onClick={closeModal}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </Dialog.Title>
                  <div
                    style={{
                      overflowY: "scroll",
                      wordWrap: "break-word",
                      marginBottom: 20,
                    }}
                    className="mt-2 w-full h-full"
                  >
                    {shop && product && adress && name ? (
                      <>
                        {shop && (
                          <>
                            <div className="text-gray-400">Tienda</div>
                            <div>{shop}</div>
                          </>
                        )}
                        {product && (
                          <>
                            <div className="text-gray-400 mt-3">Producto</div>

                            <div className="flex flex-row items-center ">
                              {id === "62fbc9ffa424480e07cdb068" && (
                                <div className="flex-shrink-0">
                                  <img
                                    src={
                                      "https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg"
                                    }
                                    className="w-12 h-12 rounded-full"
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              )}
                              {id === "62fbca6da424480e07cdb0b2" && (
                                <div className="flex-shrink-0">
                                  <img
                                    src={
                                      "https://m.media-amazon.com/images/I/61HzMcYp6aS._AC_UX679_.jpg"
                                    }
                                    className="w-12 h-12 rounded-full"
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              )}
                              {id === "62fbca52a424480e07cdb09a" && (
                                <div className="flex-shrink-0">
                                  <img
                                    src={
                                      "https://m.media-amazon.com/images/I/515OA1NDqZL._AC_UL1500_.jpg"
                                    }
                                    className="w-12 h-12 rounded-full"
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              )}
                              <div className="ml-2">{product}</div>
                            </div>
                            <div className="text-gray-400 mt-3">
                              Costo del producto
                            </div>
                            <div>$50.00</div>
                          </>
                        )}
                        {adress && (
                          <>
                            <div className="text-gray-400 mt-3">
                              Dirección de entrega
                            </div>
                            <div>{adress}</div>
                          </>
                        )}
                        {name && (
                          <>
                            <div className="text-gray-400 mt-3">Cliente</div>
                            <div>{name}</div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* {id === "62fbc9ffa424480e07cdb068" && (
                          <>
                            <div className="text-gray-400">Tienda</div>
                            <div>Amazon</div>
                            <div className="text-gray-400 mt-3">Producto</div>
                            <div className="flex flex-row items-center ">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    "https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg"
                                  }
                                  className="w-12 h-12 rounded-full"
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                              <div className="ml-2">{product}</div>
                            </div>
                            <div className="text-gray-400 mt-3">
                              Costo del producto
                            </div>
                            <div>$50.00</div>
                            <div className="text-gray-400 mt-3">
                              Dirección de entrega
                            </div>
                            <div>Direccion</div>
                            <div className="text-gray-400 mt-3">Cliente</div>
                            <div>Cliente</div>
                          </>
                        )}
                        {id === "62fbca6da424480e07cdb0b2" && (
                          <>
                            <div className="text-gray-400">Tienda</div>
                            <div>Walmart</div>
                            <div className="text-gray-400 mt-3">Producto</div>
                            <div className="flex flex-row items-center ">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    "https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg"
                                  }
                                  className="w-12 h-12 rounded-full"
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                              <div className="ml-2">{product}</div>
                            </div>
                            <div className="text-gray-400 mt-3">
                              Costo del producto
                            </div>
                            <div>$50.00</div>
                            <div className="text-gray-400 mt-3">
                              Dirección de entrega
                            </div>
                            <div>Direccion</div>
                            <div className="text-gray-400 mt-3">Cliente</div>
                            <div>Cliente</div>
                          </>
                        )}
                        {id === "62fbca52a424480e07cdb09a" && (
                          <>
                            <div className="text-gray-400">Tienda</div>
                            <div>Target</div>
                            <div className="text-gray-400 mt-3">Producto</div>
                            <div className="flex flex-row items-center ">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    "https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg"
                                  }
                                  className="w-12 h-12 rounded-full"
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                              <div className="ml-2">{product}</div>
                            </div>
                            <div className="text-gray-400 mt-3">
                              Costo del producto
                            </div>
                            <div>$50.00</div>
                            <div className="text-gray-400 mt-3">
                              Dirección de entrega
                            </div>
                            <div>Direccion</div>
                            <div className="text-gray-400 mt-3">Cliente</div>
                            <div>Cliente</div>
                          </>
                        )} */}
                      </>
                    )}
                  </div>
                  {/* 
                  <div className="mt-14 ">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Aceptar
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
