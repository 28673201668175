import React from "react";
import AceEditor from "react-ace";

function Edi358(props) {
  const { customer } = props.data[0];

  const data = `
ISA*00* *00* *02*8OPT *ZZ*CBP-ACE *220907*1658*~*00406*015946090*0*P*:^
GS*BD*8OPT*CBP-ACE*20220907*1658*15946090*X*00406USCBP^
ST*358*0001^
M10*RYCN*J***549899*20220907***S***15946090*****8^
VEH**3HSDZAPR7NN856743*******PA48118********CA***TR*****124009*ON^
NM1*EJ*0*${
    customer.name ? customer.name : customer.first_name
  }* * ** *PY*42700135891201^
P4*3802*20220907***0000^
VID*TL**297774*******I****T8556H**CA**ON^
M7*1086433*HONDABOND^
SE*8*0001^
GE*1*15946090^
IEA*1*015946090^
  `;

  return (
    <div
      className="mt-2 w-full h-full text-justify pb-10 pr-10 pl-10"
      style={{
        overflowY: "scroll",
        wordWrap: "break-word",
        marginBottom: 20,
      }}
    >
      <AceEditor
        width="100%"
        height="100%"
        mode="javascript"
        theme="tomorrow"
        name="editor"
        value={data}
        fontSize={14}
        editorProps={{ $blockScrolling: true }}
      />
      {/* <div>{`ISA*00*          *00*          *02*8OPT           *ZZ*CBP-ACE        *220907*1658*~*00406*015946090*0*P*:^`}</div>
      <div>{`GS*BD*8OPT*CBP-ACE*20220907*1658*15946090*X*00406USCBP^`}</div>
      <div>{`ST*358*0001^`}</div>
      <div>{`M10*RYCN*J***549899*20220907***S***15946090*****8^`}</div>
      <div>{`VEH**3HSDZAPR7NN856743*******PA48118********CA***TR*****124009*ON^`}</div>
      <div>{`NM1*EJ*0*${
        customer.name ? customer.name : customer.first_name
      }* * **  *PY*42700135891201^`}</div>
      <div>{`P4*3802*20220907***0000^`}</div>
      <div>{`VID*TL**297774*******I****T8556H**CA**ON^`}</div>
      <div>{`M7*1086433*HONDABOND^`}</div>
      <div>{`SE*8*0001^`}</div>
      <div>{`GE*1*15946090^`}</div>
      <div>{`IEA*1*015946090^`}</div> */}
    </div>
  );
}

export default Edi358;
