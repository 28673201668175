// eslint-disable-next-line import/no-anonymous-default-export

const generateJSON = ({
  orderNumber,
  dateOrder,
  shop,
  adress,
  name,
  product,
  productPhoto,
  id,
}) => {
  return {
    id: id,
    productPhoto: productPhoto,
    product: product,
    name: name,
    store: shop,
    adress: adress,
    pay: false,
    t1Id: "",
    orderNumber: orderNumber,
    dateOrder: dateOrder,
    customer: {
      phone: "CAMBIAR",
      email: "CAMBIAR",
      card: {},
      paymentIntent: null,
      ordenPaypal: "3RB83942KX3768354",
      useSavedCard: false,
    },
    billingAddress: {},
    notes: "",
    birthDay: 0,
    gender: "",
    startAddress: 0,
    shippingAddress: {
      addressName: "",
      country: "CAMBIAR",
      _id: "62a37c3b4ce2163bce198b90",
      city: "CAMBIAR",
      outNumber: "CAMBIAR",
      postalCode: "CAMBIAR",
      state: "CAMBIAR",
      street: "CAMBIAR",
      suburb: "CAMBIAR",
      name: "CAMBIAR",
      surname: "CAMBIAR",
      email: "CAMBIAR",
      birthDay: null,
      gender: "",
      startAddress: 0,
      phone: "CAMBIAR",
      business: "",
      codeState: "CAMBIAR",
    },
    monthlyPays: 1,
    paymethod: "PayPal",
    paymethod_id: "62a36b189d7f5a3bba723056",
    shippingMethod: {
      _id: "62a631abc8ab8a66986c7fc7",
      type: {
        displayName: "Unique price",
        name: "uniquePrice",
        showApiKeys: false,
        automated: false,
      },
      rules: {
        exclude: [],
        weight: [],
        totalPrice: [],
        zipCode: [],
        prodQty: [],
        virtualCart: [],
        brandQty: [],
      },
      extendedZone: {
        active: false,
        carrier: "dhl",
        price: 0,
      },
      price: 10,
      appliesTo: ["eshop", "retailer"],
      isT1Envios: false,
      isSubscription: false,
      collectionName: "shippingMethod",
      createdAt: 1655058859683,
      storesAddress: [],
      name: "Envío Internacional",
      description: "",
      instructions: "",
      availability: "mx",
      code: 1,
      __v: 0,
      id: "62a631abc8ab8a66986c7fc7",
    },
    paymentTerm: null,
    isSubscription: false,
  };
};

export default generateJSON;
