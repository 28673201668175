import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import generateJSON from "../lib/generateJSON";
import { size, isEmpty } from "lodash";
import { UseLocalStorage } from "../components/hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function CheckoutTarget(props) {
  let navigate = useNavigate();

  const [adress, setAdress] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = UseLocalStorage("orders", "");
  var informacionOrden = {};
  const shop = "Target";
  const productPhoto =
    "https://m.media-amazon.com/images/I/515OA1NDqZL._AC_UL1500_.jpg";
  const product = `  Red Bull Racing - Official Formula 1 Merchandise - Sergio Perez Checo
          T-Shirt - Men - Navy - L`;
  const id = "62fbca52a424480e07cdb09a";

  function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  const handledBuyClick = () => {
    if (isEmpty(name)) {
      swal({
        title: "Enter your name",
        icon: "warning",
      });
    } else if (isEmpty(adress)) {
      swal({
        title: "Enter delivery address",
        icon: "warning",
      });
    } else {
      //TODO generar aleatoreamente el ID de la Orden
      // eslint-disable-next-line no-unused-vars
      const json = generateJSON({
        orderNumber: generateUUID(),
        dateOrder: new Date(),
        shop,
        adress,
        name,
        product,
        productPhoto,
        id,
      });

      //TODO Pushearlo al Array que existe en el local storage "orders"
      if (size(order) > 0) {
        informacionOrden = [...order, json];
        setOrder(informacionOrden);
      } else {
        informacionOrden = [json];
        setOrder(informacionOrden);
      }
      swal({
        title: "Success",
        text: "Your order has been placed",
        icon: "success",
      }).then((res) => {
        if (res) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
    }
  };

  return (
    <>
      <div
        className="h-12 leading-6 bg-red-700 text-zinc-800"
        style={{ outline: 0, transition: "height 0.4s ease-in-out 0s" }}
      >
        <div
          className="flex justify-between items-center px-5 my-0 mx-auto h-12 text-zinc-800"
          style={{ outline: 0, maxWidth: 1400 }}
        >
          <div className="flex justify-start" style={{ outline: 0 }}>
            <div className="text-zinc-300" style={{ outline: 0 }}>
              <button
                type="button"
                aria-expanded="false"
                data-test="@web/StoreMessage/Button"
                id="web-store-id-msg-btn"
                className="flex relative flex-col justify-center p-0 m-0 h-10 font-sans text-base not-italic tracking-normal leading-none text-left normal-case align-middle bg-transparent rounded border border-transparent border-solid cursor-pointer select-none box-border truncate indent-0 focus:outline-offset-2 focus:outline-zinc-300"
                style={{
                  boxShadow: "none",
                  alignItems: "start",
                  fontWeight: "normal",
                  textShadow: "none",
                  textDecoration: "none",
                }}
              >
                <div
                  className="text-sm leading-3 text-white whitespace-nowrap"
                  data-test="@web/StoreMessage/NoStore"
                  style={{ outline: 0 }}
                >
                  Please select a store
                </div>
              </button>
              <div
                aria-live="polite"
                className="inline-block overflow-hidden absolute -m-px w-px h-px"
                style={{ outline: 0, clip: "rect(0px, 0px, 0px, 0px)" }}
              />
            </div>
          </div>
          <div className="flex justify-end" style={{ outline: 0 }}>
            <div className="hidden items-center sm:flex" style={{ outline: 0 }}>
              <div className="hidden md:flex" style={{ outline: 0 }}>
                <div
                  className="overflow-visible relative p-2 ml-8 text-xs text-white align-baseline bg-transparent border-0 cursor-pointer focus:text-zinc-300 focus:outline-dashed focus:outline-1 hover:text-zinc-300"
                  data-ff-lnk-nav-click-text="Registry"
                  data-test="@web/GlobalHeader/UtilityHeader/Registry"
                  id="utilityNav-registries"
                  to="/gift-registry"
                  style={{ textDecoration: "none", boxShadow: "none" }}
                >
                  Registry
                </div>
                <div
                  className="overflow-visible relative p-2 ml-8 text-xs text-white align-baseline bg-transparent border-0 cursor-pointer focus:text-zinc-300 focus:outline-dashed focus:outline-1 hover:text-zinc-300"
                  data-ff-lnk-nav-click-text="Weekly Ad"
                  data-test="@web/GlobalHeader/UtilityHeader/WeeklyAd"
                  id="utilityNav-weeklyAd"
                  style={{ textDecoration: "none", boxShadow: "none" }}
                >
                  Weekly Ad
                </div>
                <div
                  className="overflow-visible relative p-2 ml-8 text-xs text-white align-baseline bg-transparent border-0 cursor-pointer focus:text-zinc-300 focus:outline-dashed focus:outline-1 hover:text-zinc-300"
                  data-ff-lnk-nav-click-text="RedCard"
                  data-test="@web/GlobalHeader/UtilityHeader/Redcard"
                  id="utilityNav-redCardLI"
                  to="/redcard/about"
                  style={{ textDecoration: "none", boxShadow: "none" }}
                >
                  RedCard
                </div>
                <div
                  className="overflow-visible relative p-2 ml-8 text-xs text-white align-baseline bg-transparent border-0 cursor-pointer focus:text-zinc-300 focus:outline-dashed focus:outline-1 hover:text-zinc-300"
                  data-ff-lnk-nav-click-text="Target Circle"
                  data-test="@web/GlobalHeader/UtilityHeader/TargetCircle"
                  id="utilityNav-circle"
                  to="/circle"
                  style={{ textDecoration: "none", boxShadow: "none" }}
                >
                  Target Circle
                </div>
                <div
                  className="overflow-visible relative p-2 ml-8 text-xs text-white align-baseline bg-transparent border-0 cursor-pointer focus:text-zinc-300 focus:outline-dashed focus:outline-1 hover:text-zinc-300"
                  data-ff-lnk-nav-click-text="Find Stores"
                  data-test="@web/GlobalHeader/UtilityHeader/FindStores"
                  id="utilityNav-findStores"
                  to="/store-locator/find-stores"
                  style={{ textDecoration: "none", boxShadow: "none" }}
                >
                  Find Stores
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECCION */}
      <div
        className="relative px-5 my-0 mx-auto leading-6 text-zinc-800"
        style={{ outline: 0, maxWidth: 1400 }}
      >
        <nav
          data-test="@web/HeaderPrimaryNav"
          id="headerPrimary"
          className="flex relative items-center p-0 h-16 bg-white md:h-20 md:py-0 md:pr-4 md:pl-3"
          style={{ zIndex: 1049 }}
        >
          <a
            href="/"
            className="hidden overflow-visible relative p-0 align-baseline bg-transparent border-0 cursor-pointer xl:mr-2 md:mr-6 md:inline-flex md:items-center text-stone-500 focus:text-zinc-800 focus:outline-dashed focus:outline-1 hover:text-zinc-800"
            aria-label="Target home"
            style={{ textDecoration: "underline", boxShadow: "none" }}
          >
            <span
              className="inline-block flex-shrink-0 w-12 h-12 font-sans text-base not-italic tracking-normal text-red-700 normal-case align-baseline whitespace-normal indent-0"
              data-icon-name="LogoBullseyeRed"
              fill="#cc0000"
              style={{
                outline: 0,
                fill: "rgb(204, 0, 0)",
                lineHeight: "1.4285",
                fontWeight: "normal",
                textShadow: "none",
              }}
            >
              <div
                className="w-full h-full"
                style={{ outline: 0 }}
                onClick={() => navigate("/")}
              >
                <img
                  alt=""
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Target_Corporation_logo_%28vector%29.svg/2048px-Target_Corporation_logo_%28vector%29.svg.png"
                  focusable="false"
                  role="presentation"
                  className="block w-full h-full"
                  style={{ fill: "white", stroke: "none" }}
                />
              </div>
            </span>
          </a>
          <div className="relative w-10 h-10 xl:hidden" style={{ outline: 0 }}>
            <div
              aria-expanded="false"
              aria-label="Main menu"
              data-test="@web/Header/MainMenuMobileLink"
              className="flex relative flex-none justify-center items-center w-10 h-10 text-base cursor-pointer xl:ml-2 xl:h-10 xl:py-0 xl:pr-6 xl:pl-4 md:border md:border-dashed md:border-transparent md:p-1 focus,md:rounded-lg focus,md:border focus,md:border-dashed focus,md:border-zinc-800 focus,md:bg-white focus:outline-dashed focus:outline-1 focus:outline-offset-2 hover:rounded-lg hover:bg-neutral-100"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              <span
                className="inline-block flex-shrink-0 w-6 h-6 font-sans not-italic tracking-normal normal-case align-baseline whitespace-normal indent-0"
                data-icon-name="NavigationHamburgerFill"
                fill="#333333"
                size={24}
                style={{
                  outline: 0,
                  fill: "rgb(51, 51, 51)",
                  lineHeight: "1.4285",
                  fontWeight: "normal",
                  textShadow: "none",
                }}
              >
                <div
                  className="w-full h-full text-zinc-800"
                  style={{ outline: 0 }}
                ></div>
              </span>
            </div>
          </div>

          <div
            className="inline-flex overflow-visible absolute top-1/2 left-4 flex-none order-1 items-center p-0 align-baseline bg-transparent border-0 cursor-pointer md:hidden text-stone-500 focus:text-zinc-800 focus:outline-dashed focus:outline-1 hover:text-zinc-800"
            aria-label="Target home"
            style={{
              textDecoration: "underline",
              boxShadow: "none",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span
              className="inline-block flex-shrink-0 w-10 h-10 font-sans text-base not-italic tracking-normal text-red-700 normal-case align-baseline whitespace-normal indent-0"
              data-icon-name="LogoBullseyeRed"
              fill="#cc0000"
              style={{
                outline: 0,
                fill: "rgb(204, 0, 0)",
                lineHeight: "1.4285",
                fontWeight: "normal",
                textShadow: "none",
              }}
            >
              <div
                className="w-full h-full"
                style={{ outline: 0 }}
                onClick={() => navigate("/")}
              >
                <img
                  alt=""
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Target_Corporation_logo_%28vector%29.svg/2048px-Target_Corporation_logo_%28vector%29.svg.png"
                  focusable="false"
                  role="presentation"
                  className="block w-full h-full"
                  style={{ fill: "white", stroke: "none" }}
                />
              </div>
            </span>
          </div>

          <div
            className="hidden md:relative md:mx-6 md:my-0 md:flex md:flex-auto md:items-center md:justify-end"
            style={{ outline: 0 }}
          >
            <form className="flex relative p-0 m-0 w-full h-10 rounded-lg md:h-10 md:pl-0 bg-neutral-100">
              <input
                aria-label="Search: suggestions appear below"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                data-test="@web/Search/SearchInput"
                id="search"
                placeholder="Search"
                type="search"
                className="box-content flex relative py-0 pr-20 pl-3 m-0 w-full text-base bg-transparent rounded-none border-0 cursor-text resize-none md:py-3 md:pr-20 md:pl-3"
                defaultValue=""
                style={{ outline: "none" }}
              />
              <button
                aria-label="go"
                data-test="@web/Search/SearchButton"
                type="submit"
                className="absolute top-0 right-0 p-0 m-0 w-10 h-12 text-center text-transparent normal-case align-middle bg-transparent border-0 opacity-100 cursor-pointer focus:outline-offset-2"
                style={{ boxShadow: "none" }}
              >
                go
              </button>
            </form>
          </div>
          <div
            aria-expanded="false"
            aria-label="Account"
            data-test="@web/AccountLinkMobile"
            className="flex relative justify-center items-center mr-2 ml-auto w-10 h-10 cursor-pointer md:hidden md:border md:border-dashed md:border-transparent md:p-1 focus,md:rounded-lg focus,md:border focus,md:border-dashed focus,md:border-zinc-800 focus,md:bg-white focus:outline-dashed focus:outline-1 focus:outline-offset-2 hover:rounded-lg hover:bg-neutral-100"
            style={{ textDecoration: "none" }}
          >
            <div
              className="flex justify-center items-center w-10 h-10 md:flex md:items-center md:text-zinc-800"
              style={{ outline: 0 }}
            >
              <img
                alt="Account"
                src="https://target.scene7.com/is/content/Target/GUEST_ad24d0ff-62d6-4c7b-8b24-ae701e51b0f6"
                className="block max-w-full h-auto md:ml-0 md:h-6 md:w-6"
              />
            </div>
          </div>
          <div
            aria-expanded="false"
            aria-label="Account, sign in"
            data-test="@web/AccountLink"
            className="hidden relative justify-center items-center mr-2 ml-auto w-10 h-10 cursor-pointer md:mr-4 md:w-auto md:border md:border-dashed md:border-transparent md:py-0 md:pr-6 md:pl-0 focus,md:rounded-lg focus,md:border focus,md:border-dashed focus,md:border-zinc-800 focus,md:bg-white focus:outline-dashed focus:outline-1 focus:outline-offset-2 hover:rounded-lg hover:bg-neutral-100"
            style={{ textDecoration: "none" }}
          >
            <div
              className="flex justify-center items-center w-10 h-10 md:flex md:items-center md:text-zinc-800"
              style={{ outline: 0 }}
            >
              <img
                alt="Account"
                src="https://target.scene7.com/is/content/Target/GUEST_ad24d0ff-62d6-4c7b-8b24-ae701e51b0f6"
                className="block max-w-full h-auto md:ml-0 md:h-6 md:w-6"
              />
              <span
                className="inline-block flex-shrink-0 w-3 h-3 font-sans text-base not-italic tracking-normal normal-case align-baseline whitespace-normal md:absolute md:hidden md:h-3 md:w-3 indent-0"
                data-icon-name="NavigationArrowDownFill"
                fill="#333333"
                size={12}
                style={{
                  outline: 0,
                  fill: "rgb(51, 51, 51)",
                  lineHeight: "1.4285",
                  fontWeight: "normal",
                  textShadow: "none",
                }}
              >
                <div
                  className="w-full h-full text-zinc-800"
                  style={{ outline: 0 }}
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    focusable="false"
                    role="presentation"
                    className="block w-full h-full"
                    style={{ fill: "white", stroke: "none" }}
                  >
                    <defs className="">
                      <path
                        id="nds-Icon2484a"
                        d="M9.06 12.5l7.47-7.47-1.06-1.06-8.53 8.53 8.53 8.53 1.06-1.06z"
                        className=""
                      />
                    </defs>
                    <use
                      transform="scale(1 -1) rotate(90 24.235 0)"
                      xlinkHref="#nds-Icon2484a"
                      className=""
                    />
                  </svg>
                </div>
              </span>
            </div>
            <span
              className="overflow-hidden text-sm leading-5 overflow-ellipsis"
              style={{ outline: 0, maxWidth: 164, display: "-webkit-box" }}
            >
              Sign in
            </span>
          </div>
          <div
            data-test="@web/CartLink"
            className="flex relative flex-grow-0 flex-shrink-0 justify-center items-center w-10 h-10 cursor-pointer md:border md:border-dashed md:border-transparent md:p-1 focus,md:rounded-lg focus,md:border focus,md:border-dashed focus,md:border-zinc-800 focus,md:bg-white focus:outline-dashed focus:outline-1 focus:outline-offset-2 hover:rounded-lg hover:bg-neutral-100"
            style={{ textDecoration: "none", flexBasis: 44 }}
          >
            <div
              data-test="@web/CartIcon"
              className="w-6 h-6 text-zinc-800"
              style={{ outline: 0 }}
            >
              <span
                className="inline-block overflow-hidden absolute -m-px w-px h-px"
                style={{ outline: 0, clip: "rect(0px, 0px, 0px, 0px)" }}
              >
                cart 0 items
              </span>
              <span
                className="inline-block flex-shrink-0 w-6 h-6 font-sans text-base not-italic tracking-normal normal-case align-baseline whitespace-normal indent-0"
                data-icon-name="CommerceCartLine"
                fill="#333"
                size={24}
                style={{
                  outline: 0,
                  fill: "rgb(51, 51, 51)",
                  lineHeight: "1.4285",
                  fontWeight: "normal",
                  textShadow: "none",
                }}
              >
                <div className="w-full h-full" style={{ outline: 0 }}>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    focusable="false"
                    role="presentation"
                    className="block w-full h-full"
                    style={{ fill: "white", stroke: "none" }}
                  >
                    <defs className="">
                      <path
                        d="M5.625 19.25c.77 0 1.375.605 1.375 1.375S6.395 22 5.625 22s-1.375-.605-1.375-1.375.605-1.375 1.375-1.375zm13 0c.77 0 1.375.605 1.375 1.375S19.395 22 18.625 22s-1.375-.605-1.375-1.375.604-1.375 1.375-1.375zM1.135 2.212l2.962.543 18.762 2.622-2.29 7.853-13.855.492.368 2.167c.094.558.55.977 1.103 1.034l.13.007H20.25v1.5H8.314a2.75 2.75 0 01-2.677-2.12l-.034-.17-.427-2.514L3.36 4.144.865 3.688l.27-1.476zm3.798 2.175l1.503 7.844 12.996-.462 1.5-5.147-16-2.235z"
                        id="nds-Icon2485a"
                        className=""
                      />
                    </defs>
                    <g fill="none" fillRule="evenodd" className="">
                      <mask id="nds-Icon2485b" fill="#fff" className="">
                        <use xlinkHref="#nds-Icon2485a" className="" />
                      </mask>
                      <use
                        fill="#333"
                        fillRule="nonzero"
                        xlinkHref="#nds-Icon2485a"
                        className=""
                      />
                      <g mask="url(#nds-Icon2485b)" fill="#333" className="">
                        <path d="M0 0h24v24H0z" className="" />
                      </g>
                    </g>
                  </svg>
                </div>
              </span>
            </div>
          </div>
        </nav>
        <div
          className="absolute lg:hidden"
          style={{
            outline: 0,
            top: 64,
            left: 16,
            right: 16,
            zIndex: 1050,
            transition:
              "top 0.4s ease-in-out 0s, left 0.4s ease-out 0s, opacity 0.4s ease-in-out 0.4s, width 0.4s ease-in-out 0s",
          }}
        >
          <div className="relative md:hidden" style={{ outline: 0 }}>
            <button
              aria-label="Search"
              type="button"
              style={{ width: "100%", boxShadow: "none" }}
              className="p-0 m-0 w-full text-center normal-case align-middle bg-transparent border-0 cursor-pointer focus:outline-offset-2"
            >
              <form className="flex relative p-0 m-0 w-full h-10 rounded-lg md:h-10 md:pl-0 bg-neutral-100">
                <input
                  data-test="@web/SearchInputMobile"
                  placeholder="Search"
                  readOnly=""
                  tabIndex={-1}
                  type="text"
                  className="flex relative py-0 pr-10 pl-3 m-0 w-full text-base rounded-none border-0 appearance-none cursor-text resize-none md:py-3 md:pr-20 md:pl-3 box-border"
                  defaultValue=""
                  style={{ outline: "none" }}
                />
              </form>
            </button>
          </div>
        </div>
      </div>
      {/* SECCION */}
      <div
        className="flex flex-initial items-center mb-1  w-full leading-6 box-border text-zinc-800 ml-4 mt-10"
        style={{ outline: 0, flexFlow: "row wrap", minHeight: 49 }}
      >
        <div
          className="flex flex-initial box-border"
          style={{ outline: 0, flexFlow: "row wrap" }}
        >
          <div
            className="flex flex-initial box-border"
            style={{ outline: 0, flexFlow: "row wrap" }}
          >
            <div className="ml-2 text-zinc-800" style={{ outline: 0 }}>
              <h1
                className="mx-0 mt-0 mb-2 font-sans text-3xl not-italic tracking-normal leading-tight normal-case align-baseline whitespace-normal indent-0"
                tabIndex={-1}
                style={{ outline: 0, textShadow: "none", fontWeight: "bold" }}
              >
                Cart
              </h1>
              <div className="mb-3 text-stone-500" style={{ outline: 0 }}>
                <span
                  className="text-lg font-bold leading-7"
                  style={{ outline: 0 }}
                >
                  $50.00 subtotal
                  <span
                    className="inline-block flex-shrink-0 pt-1 w-4 h-4 font-sans text-base not-italic tracking-normal normal-case align-baseline whitespace-normal indent-0"
                    data-icon-name="MediaVolumeControl"
                    fill="#666666"
                    style={{
                      outline: 0,
                      fill: "rgb(102, 102, 102)",
                      lineHeight: "1.4285",
                      fontWeight: "normal",
                      textShadow: "none",
                    }}
                  >
                    <div className="w-full h-full" style={{ outline: 0 }}>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        focusable="false"
                        role="presentation"
                        className="block w-full h-full"
                        style={{ fill: "white", stroke: "none" }}
                      >
                        <defs className="">
                          <circle
                            id="nds-Icon511a"
                            cx={12}
                            cy={12}
                            r={7}
                            className=""
                          />
                        </defs>
                        <use
                          xlinkHref="#nds-Icon511a"
                          fillRule="evenodd"
                          className=""
                        />
                      </svg>
                    </div>
                  </span>
                  1 item
                </span>
              </div>
            </div>
            <div
              className="flex flex-initial pb-3 box-border"
              style={{
                outline: 0,
                flexFlow: "row wrap",
                placeContent: "flex-end",
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-3 border border-gray-300 rounded-lg font-black my-3 mx-2">
        <h1 className="text-gray-800 mb-1">Shiping: 1 item</h1>
        <div className="flex flex-row">
          <img
            src="https://m.media-amazon.com/images/I/515OA1NDqZL._AC_UL1500_.jpg"
            className="w-1/4"
            alt=""
          />
          <div className="ml-2 w-3/4">
            <p className="truncate font-light leading-tight">
              Red Bull Racing - Official Formula 1 Merchandise - Sergio Perez
              Checo T-Shirt - Men - Navy - L
            </p>
            <div className="items-center flex flex-row">
              <div></div>
            </div>
            <p className="text-red-700 font-light leading-tight">$50.00</p>
            <p className="text-red-700 font-light leading-tight">
              Get it by Mon, Aug 15
            </p>
          </div>
        </div>
      </div>
      <div className="m-4">
        <div className="mt-2 ml-4 text-xl flex-row flex">
          <div>Enter your name</div>
          <div className="text-red-600 ">*</div>
        </div>
        <div className="mx-4">
          <input
            className="w-full"
            onChange={(e) => setName(e.target.value)}
            name="name"
            type="text"
            value={name}
            placeholder="My name"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
            }}
          />
        </div>
        <div className="mt-2 ml-4 text-xl flex-row flex">
          <div>Select delivery address </div>
          <div className="text-red-600 "> *</div>
        </div>
        <div className="mx-4">
          <Autocomplete
            apiKey={"AIzaSyCSfb3q43wvrhTk9tbipj9KkrcVcjxW3ro"}
            onPlaceSelected={(place) => {
              setAdress(place.formatted_address);
            }}
            className="w-full"
            defaultValue={adress}
            placeholder="Find address"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
          />
        </div>
        <button
          className="rounded px-2 py-3 border border-red-700 bg-gradient-to-b from-red-700 to-red-600 w-36 mt-6 ml-4 text-white"
          onClick={handledBuyClick}
        >
          Order
        </button>
      </div>
    </>
  );
}

export default CheckoutTarget;
