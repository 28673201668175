import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { size } from "lodash";
import swal from "sweetalert";
import LayoutWidget from "./LayoutWidget";

function ProductsShopify() {
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);
  const [clasificacion, setclasificacion] = useState("");
  const [inputOpen, setInputOpen] = useState(false);
  const gridRef = useRef(null);

  useEffect(() => {
    fetchApiShopify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columnDefs] = useState([
    {
      field: "Seleccionar",
      headerName: "Seleccionar",
      checkboxSelection: true,
    },
    {
      field: "title",
      headerName: "Producto",
      filter: true,
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "product_type",
      headerName: "Categoría",
      filter: true,
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "vendor",
      headerName: "Comerciante",
      filter: true,
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "id",
      headerName: "Id",
      filter: true,
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
      resizable: true,
      floatingFilter: true,
    },
  ]);

  const fetchApiShopify = async () => {
    fetch("https://t1-edi-nextjs.vercel.app/api/productshopify", {
      method: "GET",
    })
      .then(async (res) => {
        const responeJSON = await res.json();
        const informacion = responeJSON.products;
        setData(informacion);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function classify(info) {
    window.ccce.classify(
      {
        product: clasificacion ? clasificacion : info,
        destination: "US",
        origin: "MX",
        lang: "ES",
        useKeyboard: true,
        hs6: false,
      },
      myCallback,
      myAbortFunction
    );
  }

  function init() {
    const el = document.querySelector("script[data-element-id]");
    window.ccce.init(el);
  }

  function myCallback(results) {
    swal({
      title: "Clasificación exitosa",
      text: results.hsCode,
      icon: "success",
    }).then((res) => {
      if (res) {
        setopen(false);
        setInputOpen(false);
        window.location.reload();
      } else {
        setopen(false);
        setInputOpen(false);
        window.location.reload();
      }
    });
  }

  function myAbortFunction(results) {
    console.log("user aborted classification");
    setopen(false);
    setInputOpen(false);
    window.location.reload();
  }

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedRows();
    setSeleccionados(rowCount);
    console.log(rowCount);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!inputOpen && (
        <div>
          <div className="mb-4">
            <h1 className="text-3xl font-bold text-[#D93A26]">
              Dashboard - Products Shopify
            </h1>
          </div>
        </div>
      )}
      {!inputOpen && (
        <div className="justify-between flex text-center items-center">
          {size(seleccionados) === 1 && (
            <div>
              {seleccionados.map((item, i) => (
                <input
                  key={i}
                  className="w-full"
                  onChange={(e) => setclasificacion(e.target.value)}
                  name="name"
                  type="text"
                  placeholder={item.product_type}
                  style={{
                    borderWidth: 1,
                    padding: 5,
                    borderRadius: 4,
                    marginBottom: 2,
                  }}
                />
              ))}
            </div>
          )}
          {size(seleccionados) === 1 && (
            <div>
              {seleccionados.map((item, i) => (
                <div key={i}>
                  <button
                    type="button"
                    className="text-white bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
                    onClick={() => {
                      setopen(true);
                      setInputOpen(true);
                      setTimeout(() => {
                        init();
                        classify(item.title);
                      }, 500);
                    }}
                    style={{
                      width: 120,
                    }}
                  >
                    Clasificar
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {inputOpen && (
        <div className="flex flex-row">
          <input
            className="w-full"
            onChange={(e) => setclasificacion(e.target.value)}
            name="name"
            type="text"
            placeholder="Descripción"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
              width: "50%",
              marginRight: 10,
            }}
          />
          <button
            type="button"
            className="text-white bg-[#D93A26] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-1 mb-1"
            onClick={() => {
              setopen(true);
              setInputOpen(true);
              setTimeout(() => {
                init();
                classify(clasificacion);
              }, 500);
            }}
            style={{
              width: 120,
            }}
          >
            Buscar
          </button>
        </div>
      )}
      {inputOpen && <LayoutWidget />}
      {open && (
        <div
          id="my-div-clasificate"
          style={{
            position: "absolute",
            zindex: 6000,
            borderRadius: 0,
            maxWidth: "100vw",
            maxHeight: "100vh ",
            top: 170,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        ></div>
      )}
      {!inputOpen && (
        <div className="ag-theme-alpine mb-4" style={{ height: 450 }}>
          <AgGridReact
            rowData={data}
            columnDefs={columnDefs}
            pagination={true}
            ref={gridRef}
            rowSelection={"single"}
            rowHeight={55}
            onSelectionChanged={onSelectionChanged}
            suppressMenuHide={true}
            rowGroupPanelShow={"always"}
          ></AgGridReact>
        </div>
      )}
    </>
  );
}

export default ProductsShopify;
