import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import swal from "sweetalert";
import { size } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";

export default function ModalEnvioShopify(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [openLabel, setopenLabel] = useState(false);
  const [label, setlabel] = useState("");
  const [alto, setalto] = useState(0.0);
  const [ancho, setancho] = useState(0.0);
  const [largo, setlargo] = useState(0.0);
  const [peso, setpeso] = useState(0.0);
  const [seleccionado, setseleccionado] = useState({});
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [activo, setactivo] = useState(false);
  const [loadingLabel, setloadingLabel] = useState(false);
  const { billing_address, customer } = props.seleccionados[0];
  const timestamp = Date.now();

  function closeModal() {
    setIsOpen(false);
    setloading(false);
    setactivo(false);
    setalto(0);
    setlargo(0);
    setancho(0);
    setpeso(0);
    setseleccionado({});
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleRate = () => {
    if (!parseFloat(alto) > 0) {
      swal({
        title: "Enter the Height",
        icon: "warning",
      });
    } else if (!parseFloat(ancho) > 0) {
      swal({
        title: "Enter the Width",
        icon: "warning",
      });
    } else if (!parseFloat(largo) > 0) {
      swal({
        title: "Enter the Length",
        icon: "warning",
      });
    } else if (!parseFloat(peso) > 0) {
      swal({
        title: "Enter the Weight",
        icon: "warning",
      });
    } else {
      if (props.country === "US") {
        setloading(true);
        setactivo(false);
        fetchApiRate();
      } else {
        setloading(true);
        setactivo(false);
        fetchApiRateMx();
      }
    }
  };

  const handleLabel = () => {
    if (props.country === "US") {
      setloadingLabel(true);
      fetchApiCreateOrder();
    } else {
      // setloadingLabel(true);
      // fetchApiCreateOrderMx();
      // setloadingLabel(false);
      setlabel(
        "https://ehub-prod.s3.amazonaws.com/public/files/label_images/20220913/f2f792b4-1f84-418f-abaa-628600c58a9b.png"
      );
      setopenLabel(true);
    }
  };

  const fetchApiRate = async () => {
    let headersList = {
      "Content-Type": "application/json",
      Accept: "application / json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NjEzNTM0MzIsImRhdGEiOnsidXNlciI6eyJpZCI6MTIyMzksImN1c3RvbWVyX2lkIjo4NTg0LCJlbWFpbCI6ImNlZnVlbnRlc0BtZS5jb20ifSwic2NvcGVzIjpbImFwaV9wdWJsaWMiXX19.nohkD8OsOa-0KfTHIT3bVoSmuImGCdrj4yWIRuTbMe_iAeal0N5RYhAo7Ds2eApGCQEttbkFr6RemXSf5YSOwg",
    };

    let bodyContent = JSON.stringify({
      shipment: {
        to_location: {
          company: "FlexLogic",
          first_name: customer.first_name,
          address1: "10025 Siempre Viva Rd",
          address2: "",
          city: "San Diego",
          state: "CA",
          country: "US",
          postal_code: "92154",
          phone: "000-000-0000",
        },
        from_location: {
          company: "FlexLogic",
          first_name: customer.first_name,
          address1: billing_address.address1,
          city: billing_address.city,
          state: billing_address.province_code,
          country: "US",
          postal_code: billing_address.zip,
          phone: "000-000-0000",
        },
        parcels: [
          {
            length: Number(largo),
            width: Number(ancho),
            height: Number(alto),
            weight: Number(peso),
            package_type: "parcel",
            parcel_items: [
              {
                id: 1877,
                item_id: "823",
                name: "Wrist Band",
                weight: Number(peso),
                quantity: 1.0,
                price: 1.0,
                cost: 1.0,
                customs_data: {
                  content_type: "merchandise",
                  no_delivery: "return",
                  value: 1.0,
                },
              },
            ],
            customs_data: {
              content_type: "merchandise",
              no_delivery: "return",
              value: 1.0,
            },
            service_options: {
              delivery_confirmation: "NO_SIGNATURE_REQUIRED",
            },
          },
        ],
      },
      show_all_services: true,
    });

    fetch("https://api.essentialhub.com/api/v2/rates", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        setloading(false);
        setdata(data.service_rates);
        setactivo(true);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const fetchApiCreateOrder = async () => {
    let headersList = {
      "Content-Type": "application/json",
      Accept: "application / json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NjEzNTM0MzIsImRhdGEiOnsidXNlciI6eyJpZCI6MTIyMzksImN1c3RvbWVyX2lkIjo4NTg0LCJlbWFpbCI6ImNlZnVlbnRlc0BtZS5jb20ifSwic2NvcGVzIjpbImFwaV9wdWJsaWMiXX19.nohkD8OsOa-0KfTHIT3bVoSmuImGCdrj4yWIRuTbMe_iAeal0N5RYhAo7Ds2eApGCQEttbkFr6RemXSf5YSOwg",
    };

    let bodyContent = JSON.stringify({
      shipment: {
        account_reference: "Test123456789",
        order_number: "123456789",
        to_location: {
          company: "FlexLogic",
          first_name: customer.name,
          address1: "10025 Siempre Viva Rd",
          address2: "",
          city: "San Diego",
          state: "CA",
          country: "US",
          postal_code: "92154",
          phone: "000-000-0000",
        },
        return_location: {
          company: "FlexLogic",
          first_name: customer.first_name,
          address1: billing_address.address1,
          city: billing_address.city,
          state: billing_address.province_code,
          country: "US",
          postal_code: billing_address.zip,
          phone: "000-000-0000",
        },
        from_location: {
          company: "FlexLogic",
          first_name: customer.first_name,
          address1: billing_address.address1,
          city: billing_address.city,
          state: billing_address.province_code,
          country: "US",
          postal_code: billing_address.zip,
          phone: "000-000-0000",
        },
        parcels: [
          {
            length: Number(largo),
            width: Number(ancho),
            height: Number(alto),
            weight: Number(peso),
            package_type: "parcel",
            parcel_items: [
              {
                id: 1877,
                item_id: "823",
                name: "Wrist Band",
                weight: Number(peso),
                quantity: 1.0,
                price: 1.0,
                cost: 1.0,
                customs_data: {
                  content_type: "merchandise",
                  no_delivery: "return",
                  value: 1.0,
                },
              },
            ],
            customs_data: {
              content_type: "merchandise",
              no_delivery: "return",
              value: 1.0,
            },
            service_options: {
              delivery_confirmation: "NO_SIGNATURE_REQUIRED",
            },
          },
        ],
        label_format: "png",
        label_size: "4x6",
        service_id: seleccionado.service_id,
      },
    });

    fetch("https://api.essentialhub.com/api/v2/shipments/ship", {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    })
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        setloadingLabel(false);
        setlabel(data.shipment.parcels[0].postage_label.image_url);
        swal({
          title: "Se ha creado la etiqueta correctamente",
          icon: "success",
        }).then((res) => {
          if (res) {
            setopenLabel(true);
          } else {
            setopenLabel(true);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloadingLabel(false);
      });
  };

  const fetchApiRateMx = async () => {
    let headersList = {
      "Content-Type": "application/json",
      Accept: "application / json",
      user: "62a36b189d7f5a3bba723079",
      timestamp: timestamp,
      Authorization:
        "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3M2ZiUk1KbmVSQmU4el9zRXNQZTcyRjdBZFVrNnlDZ0VnN2VwWDM3RWJvIn0.eyJleHAiOjE2NjMxMTA5NzMsImlhdCI6MTY2MzEwNzM3MywiYXV0aF90aW1lIjoxNjYzMDk0NDE1LCJqdGkiOiIyNGZmZjJmMy1iMDAwLTRkZTItODQxMS03ODZhMzc3ODEwZjMiLCJpc3MiOiJodHRwczovL2xvZ2luY2xhcm8uY29tL2F1dGgvcmVhbG1zL3BsYXRhZm9ybWEtY2xhcm8iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiY2VkY2JjYTctNWExOS00MWI1LWJkZTAtMDg4OTc3MDM5NGRlIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidDFwYWdpbmFzIiwibm9uY2UiOiJkZDY0NTZiMy0wZjY0LTQ5ZWMtODQ3Yi1jYjdiYmExN2JkYTMiLCJzZXNzaW9uX3N0YXRlIjoiNjFiZjhhOGYtNmQ4ZS00MjMwLWIxNmQtZjQ4ZmZiNWE5OWY2IiwiYWNyIjoiMCIsImFsbG93ZWQtb3JpZ2lucyI6WyIqIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImJpcnRoRGF5IjoiMDUtMDMtMTk4MiIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZ2VuZGVyIjoiTWFzY3VsaW5vIiwibW9iaWxlIjoiNjYyNDc2NTEzMyIsIm5hbWUiOiJDYXJsb3MgRnVlbnRlcyIsInByZWZlcnJlZF91c2VybmFtZSI6ImNhcmxvc0BjbHViYmFtYnUuY29tIiwiZ2l2ZW5fbmFtZSI6IkNhcmxvcyIsImZhbWlseV9uYW1lIjoiRnVlbnRlcyIsImVtYWlsIjoiY2FybG9zQGNsdWJiYW1idS5jb20ifQ.LHoCdaPHyyn6j_ruc1UqEaHzvbJ9TyojGJriuB8uZo83hQr1CpybfYPWmHShAJAvWDEfi3mKyW69cXukFR0-vXMeRL8ehlzXrP_EQxOPiuzjrwiEvYaT595gUmNtUzQr4DOSBtBgo3TE9fSLwS07Srk9JkaKxxX-uPd__wctYOW9qvE1jJlHr3Tqpkz-BsVfFOz_PIuXBiQm-Fim1R7zMUlJaWMpp5Zt4Ah8_GR17m7PMgE7U26FPNyGUutoNJ4ak2zmw6vXNK5_uy1it08fZQXWbbQldl5io9VVDphXNQLyLd6jsg6bCJ3B4dpqS_amulFk3uPjDIYS-ejc8fyEow",
    };

    let bodyContent = JSON.stringify({
      codigo_postal_origen: "11529",
      codigo_postal_destino: "22430",
      peso: Number(peso),
      largo: Number(largo),
      ancho: Number(ancho),
      alto: Number(alto),
      dias_embarque: 1,
      seguro: false,
      valor_paquete: 0,
      tipo_paquete: 1,
      comercio_id: "34026",
      commerceId: "34026",
    });

    fetch(
      "https://t1enviosapi.t1envios.com/api/v1/shipping-guide/mensajeria-cotizador",
      {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      }
    )
      .then(async (res) => {
        // let data = await response.text();
        let data = await res.json();
        console.log(data);
        setloading(false);
        setdata(data.detail);
        setactivo(true);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  return (
    <>
      <button
        type="button"
        style={{
          backgroundColor: "#D93A26",
        }}
        className="mr-4 py-2.5 px-5 text-sm font-medium text-white focus:outline-none rounded-lg border border-gray-200 hover:text-black focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        onClick={openModal}
      >
        Ship {props.country}
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="w-full h-full" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                  >
                    <div>Information</div>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                      onClick={closeModal}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </Dialog.Title>
                  <div
                    className="w-full h-full"
                    style={{
                      overflowY: "scroll",
                      wordWrap: "break-word",
                    }}
                  >
                    <div className="flex flex-row">
                      <div className="mr-1 w-1/2">
                        <div className="text-gray-500 ">Height</div>
                        <input
                          className="w-full"
                          onChange={(e) => setalto(e.target.value)}
                          name="name"
                          type="number"
                          value={alto}
                          style={{
                            borderWidth: 1,
                            padding: 5,
                            borderRadius: 4,
                          }}
                        />
                      </div>
                      <div className=" w-1/2">
                        <div className="text-gray-500">Width</div>
                        <input
                          className="w-full"
                          onChange={(e) => setancho(e.target.value)}
                          name="name"
                          type="number"
                          value={ancho}
                          style={{
                            borderWidth: 1,
                            padding: 5,
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="mr-1 w-1/2">
                        <div className="text-gray-500">Length</div>
                        <input
                          className="w-full"
                          onChange={(e) => setlargo(e.target.value)}
                          name="name"
                          type="number"
                          value={largo}
                          style={{
                            borderWidth: 1,
                            padding: 5,
                            borderRadius: 4,
                          }}
                        />
                      </div>
                      <div className="w-1/2">
                        <div className="text-gray-500">Weight</div>
                        <input
                          className="w-full"
                          onChange={(e) => setpeso(e.target.value)}
                          name="name"
                          type="number"
                          value={peso}
                          style={{
                            borderWidth: 1,
                            padding: 5,
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    </div>

                    <div className="mt-6 justify-between flex text-center">
                      <div></div>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2focus-visible:ring-offset-2 bg-[#D93A26] text-white"
                        onClick={handleRate}
                        style={{
                          width: 150,
                        }}
                      >
                        Rate
                      </button>
                    </div>
                    {loading && (
                      <div className="items-center justify-center flex mt-20">
                        <ClipLoader color="#D93A26" />
                      </div>
                    )}
                    {size(data) > 0 && (
                      <>
                        {activo && (
                          <div
                            style={{
                              marginBottom: 50,
                            }}
                          >
                            {data.map((item, i) => (
                              <div
                                key={i}
                                className="bg-gray-100 mt-8 rounded-lg p-4 flex flex-row items-center justify-between"
                              >
                                <div className="flex flex-row">
                                  <img
                                    src="https://1000marcas.net/wp-content/uploads/2022/06/USPS-Logo.png"
                                    className="w-20 ml-10"
                                    alt=""
                                  />
                                  <div className="ml-10">
                                    <div>
                                      {item.service ? item.service : item.clave}
                                    </div>
                                    {item.rate && <div>{item.rate} USD</div>}
                                    {item.cotizacion && (
                                      <div>
                                        {Object.entries(
                                          item.cotizacion.servicios
                                        ).map((item, i) => (
                                          <div key={i}>
                                            <div className="flex flex-row">
                                              <div className="mr-10">
                                                {item[0]}
                                              </div>
                                              {/* {Object.entries(item[1]).map(
                                                (item, i) => (
                                                  <div>{i}</div>
                                                )
                                              )} */}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                    {item.cotizacion && (
                                      <div>
                                        {item.cotizacion.success === false && (
                                          <div>{item.cotizacion.message}</div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <input
                                  type={"radio"}
                                  name="service"
                                  onClick={() => setseleccionado(item)}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                    {seleccionado.service && (
                      <div className="mt-6 justify-between flex text-center mb-10">
                        <div></div>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2focus-visible:ring-offset-2 bg-[#D93A26] text-white"
                          onClick={handleLabel}
                          style={{
                            width: 150,
                          }}
                        >
                          {loadingLabel && (
                            <ClipLoader
                              color="black"
                              size={20}
                              className="mr-1"
                            />
                          )}
                          Create label
                        </button>
                      </div>
                    )}
                    {seleccionado.id && (
                      <div className="mt-6 justify-between flex text-center mb-10">
                        <div></div>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2focus-visible:ring-offset-2 bg-[#D93A26] text-white"
                          onClick={handleLabel}
                          style={{
                            width: 150,
                          }}
                        >
                          {loadingLabel && (
                            <ClipLoader
                              color="black"
                              size={20}
                              className="mr-1"
                            />
                          )}
                          Create label
                        </button>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {openLabel && (
        <>
          <Transition show={openLabel} as={Fragment}>
            <Dialog
              as="div"
              className="w-full h-full"
              onClose={() => window.location.reload()}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25 " />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto items-center justify-center">
                <div className="flex min-h-full items-center justify-center p-4 text-center w-screen h-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="p-6 w-full h-full transform overflow-hidden rounded-2xl bg-white  text-left shadow-xl transition-all items-center justify-center ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 text-gray-900 font-bold justify-between flex "
                      >
                        {/* <div>Información EDI</div> */}
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-toggle="defaultModal"
                          onClick={() => window.location.reload()}
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </Dialog.Title>
                      <iframe
                        title="Label"
                        className="pb-10 pr-10 pl-10"
                        src={label}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </>
  );
}
