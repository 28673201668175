import React from "react";
import { useNavigate } from "react-router-dom";

function WalmartShop(props) {
  let navigate = useNavigate();

  return (
    <>
      <div
        className="relative py-2 px-5 m-0 min-w-0 min-h-0 text-sm leading-4 box-border text-zinc-700"
        style={{
          backgroundColor: "#004aad",
        }}
      >
        <div className="flex justify-between items-center p-0 m-0 min-w-0 min-h-0 leading-4 box-border">
          <div
            className="flex p-0 m-0 min-h-0 box-border"
            style={{ minWidth: 150 }}
          >
            <div className="flex p-0 m-0 min-w-0 min-h-0 box-border">
              <a
                href="/"
                className="flex items-center py-0 px-2 m-0 min-w-0 min-h-0 font-sans cursor-pointer box-border"
                style={{ textDecoration: "none" }}
              >
                <img
                  className="flex-grow flex-shrink-0 p-0 m-0 w-full min-w-0 h-full min-h-0 border-none box-border"
                  //src="https://ingenieria.udd.cl/files/2020/09/walmart-logo-1-1.jpg"
                  src="levolor1.png"
                  alt="walmart logo"
                  data-automation-id="walmart-logo-image"
                  style={{ maxWidth: 110 }}
                />
              </a>
            </div>
          </div>
          <div className="flex flex-grow flex-shrink-0 p-0 m-0 min-w-0 min-h-0 box-border">
            <div className="hidden p-0 m-0 min-w-0 min-h-0 md:flex md:flex-shrink-0 md:flex-grow box-border">
              <div className="flex self-center p-0 my-0 mx-auto w-full min-w-0 min-h-0 box-border">
                <div
                  role="combobox"
                  aria-haspopup="listbox"
                  aria-controls="react-autowhatever-1"
                  aria-expanded="false"
                  className="flex relative flex-auto p-0 m-0 min-w-0 h-8 min-h-0 box-border"
                >
                  <div className="flex flex-1 py-1 px-2 m-0 min-w-0 min-h-0 bg-white box-border">
                    <input
                      type="text"
                      autoComplete="off"
                      aria-autocomplete="list"
                      aria-controls="react-autowhatever-1"
                      className="overflow-visible p-0 m-0 w-full min-w-0 min-h-0 font-sans text-base border-0 cursor-text box-border"
                      placeholder="Search in online exclusives"
                      id="searchbarInput"
                      data-automation-id="search-bar"
                      data-testid="search-bar"
                      defaultValue=""
                      style={{ lineHeight: "1.15", outline: 0 }}
                    />
                    <button
                      className="inline-block overflow-visible p-0 m-0 w-auto min-w-0 h-auto font-sans text-center normal-case bg-none rounded border-0 cursor-pointer box-border"
                      type="button"
                      data-automation-id="search-icon"
                      style={{
                        minHeight: "auto",
                        lineHeight: "1.15",
                        textDecoration: "none",
                        outline: "none",
                        filter: "brightness(100%)",
                        transition:
                          "filter 0.1s ease 0s, -webkit-filter 0.1s ease 0s",
                      }}
                    >
                      <svg
                        className="p-0 m-0 w-5 min-w-0 h-6 min-h-0 box-border"
                        viewBox="0 0 25 25"
                        preserveAspectRatio="xMidYMid"
                        aria-labelledby="title"
                        style={{ fill: "rgb(1, 122, 219)" }}
                      >
                        <title
                          id="title"
                          lang="en"
                          className="p-0 m-0 min-w-0 min-h-0 box-border"
                        />
                        <path
                          d="M18.9612464,16.6839084 L17.6820504,16.6349255 L18.5078605,17.4833159 C20.0947111,15.6258155 21.05006,13.2143236 21.05006,10.5910115 C21.05006,4.74151439 16.338085,0 10.52503,0 C4.71197497,0 0,4.74151439 0,10.5910115 C0,16.4405087 4.71197497,21.182023 10.52503,21.182023 C13.131999,21.182023 15.5284673,20.2206851 17.3743957,18.6238864 L16.5323933,17.7917898 L16.5323933,19.127988 L22.5873393,25 L25,22.5722143 L18.9612464,16.6839084 Z M10.52503,17.9232503 C6.4931339,17.9232503 3.23847077,14.6481836 3.23847077,10.5910115 C3.23847077,6.53383942 6.4931339,3.25877278 10.52503,3.25877278 C14.5569261,3.25877278 17.8115892,6.53383942 17.8115892,10.5910115 C17.8115892,14.6481836 14.5569261,17.9232503 10.52503,17.9232503 Z"
                          className="p-0 m-0 min-w-0 min-h-0 box-border"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-none self-center p-0 m-0 min-w-0 min-h-0 box-border">
              <div
                className="flex items-center p-0 m-0 min-w-0 min-h-0 font-sans text-white cursor-pointer md:mr-2 md:ml-5 box-border"
                data-automation-id="my-products-link"
                style={{ textDecoration: "none" }}
              >
                <div className="p-0 m-0 min-w-0 min-h-0 box-border">
                  <p className="p-0 m-0 min-w-0 min-h-0 text-xs leading-3 box-border">
                    Order them again
                  </p>
                  <p className="p-0 m-0 min-w-0 min-h-0 text-base font-bold leading-5 box-border">
                    Your products
                  </p>
                </div>
              </div>
              <div
                className="relative p-0 m-0 min-w-0 min-h-0 box-border"
                data-automation-id="header-dropdown"
              >
                <div className="py-0 pr-16 pl-5 m-0 min-w-0 min-h-0 cursor-pointer box-border">
                  <div
                    className="flex items-center p-0 m-0 min-w-0 min-h-0 font-sans text-white box-border"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="p-0 m-0 min-w-0 min-h-0 box-border ">
                      <span
                        data-automation-id="your-account"
                        className="block p-0 m-0 min-w-0 min-h-0 box-border"
                        style={{ lineHeight: "1.15" }}
                      >
                        Your account
                      </span>
                    </div>
                    <svg
                      className="p-0 my-0 mr-2 ml-0 w-6 min-w-0 h-6 min-h-0 box-border"
                      viewBox="0 4 35 35"
                      preserveAspectRatio="xMidYMid"
                      aria-labelledby="title"
                      style={{ fill: "rgb(255, 255, 255)" }}
                    >
                      <title
                        id="title"
                        lang="en"
                        className="p-0 m-0 min-w-0 min-h-0 box-border"
                      />
                      <path
                        d="m31 16.4q0 0.3-0.2 0.5l-10.4 10.4q-0.3 0.3-0.5 0.3t-0.6-0.3l-10.4-10.4q-0.2-0.2-0.2-0.5t0.2-0.5l1.2-1.1q0.2-0.2 0.5-0.2t0.5 0.2l8.8 8.8 8.7-8.8q0.3-0.2 0.5-0.2t0.6 0.2l1.1 1.1q0.2 0.2 0.2 0.5z"
                        className="p-0 m-0 min-w-0 min-h-0 box-border"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="hidden absolute top-full p-0 m-0 min-w-0 min-h-0 box-border"
                  style={{ left: 20, zIndex: 9 }}
                >
                  <div
                    className="p-2 m-0 min-w-0 min-h-0 bg-white box-border"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                      zIndex: 1000,
                    }}
                  >
                    <span
                      className="absolute left-1/2 p-0 my-0 mr-0 -ml-1 w-0 min-w-0 h-0 min-h-0 font-sans border-t-0 border-transparent border-solid box-border text-zinc-800"
                      style={{
                        lineHeight: "1.3",
                        top: "-6px",
                        borderWidth: "0px 15px 15px",
                      }}
                    />
                    <a
                      data-automation-id="login-dropdown-iniciar"
                      href="/tu-cuenta/iniciar-sesion"
                      className="flex items-center p-0 mx-0 mt-1 mb-0 min-w-0 min-h-0 font-sans text-black cursor-pointer box-border"
                      style={{ textDecoration: "none" }}
                    >
                      Iniciar sesión
                    </a>
                    <a
                      data-automation-id="login-dropdown-crear-cuenta"
                      href="/tu-cuenta/crear-cuenta"
                      className="flex items-center p-0 mx-0 mt-4 mb-0 min-w-0 min-h-0 font-sans text-black cursor-pointer box-border"
                      style={{ textDecoration: "none" }}
                    >
                      Crear cuenta
                    </a>
                    <a
                      data-automation-id="login-dropdown-consultar-tu-pedido"
                      href="/tu-cuenta/consultar-pedido"
                      className="flex items-center p-0 mx-0 mt-4 mb-0 min-w-0 min-h-0 font-sans text-black cursor-pointer box-border"
                      style={{ textDecoration: "none" }}
                    >
                      Consultar tu pedido
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SECCION*/}
      <div
        className="text-sm leading-5 text-zinc-800 ml-4 mt-4"
        data-test="@web/Breadcrumb/Container"
        style={{ outline: 0 }}
      >
        <span className="leading-5" style={{ outline: 0 }}>
          <span className="text-zinc-800" style={{ outline: 0 }}>
            <a
              href="/"
              className="overflow-visible relative p-0 align-baseline bg-transparent border-0 cursor-pointer text-blue-500 focus:text-zinc-800  hover:text-zinc-800"
              aria-label="Target"
              data-test="@web/Breadcrumb/BreadcrumbLink"
              itemProp="url"
              style={{ boxShadow: "none" }}
            >
              <span
                aria-hidden="true"
                itemProp="name"
                className=""
                style={{ outline: 0 }}
              >
                Regresar
              </span>
            </a>
          </span>
          <span
            aria-hidden="true"
            className="px-1 text-zinc-800"
            style={{ outline: 0 }}
          ></span>
        </span>
        <span className="leading-5" style={{ outline: 0 }}>
          <span className="text-zinc-800" style={{ outline: 0 }}>
            <a
              href="/"
              className="overflow-visible relative p-0 align-baseline bg-transparent border-0 cursor-pointer text-stone-500 focus:text-zinc-800 focus:outline-dashed focus:outline-1 hover:text-zinc-800"
              aria-label="Movies, Music & Books"
              data-test="@web/Breadcrumb/BreadcrumbLink"
              itemProp="url"
              style={{ boxShadow: "none" }}
            >
              <span
                aria-hidden="true"
                itemProp="name"
                className=""
                style={{ outline: 0 }}
              >
                Sports
              </span>
            </a>
          </span>
          <span
            aria-hidden="true"
            className="px-1 text-zinc-800"
            style={{ outline: 0 }}
          >
            {`>`}
          </span>
        </span>
        <span className="leading-5" style={{ outline: 0 }}>
          <span className="text-zinc-800" style={{ outline: 0 }}>
            <a
              href="/"
              className="overflow-visible relative p-0 align-baseline bg-transparent border-0 cursor-pointer text-stone-500 focus:text-zinc-800 focus:outline-dashed focus:outline-1 hover:text-zinc-800"
              aria-label="Books"
              data-test="@web/Breadcrumb/BreadcrumbLink"
              itemProp="url"
              style={{ boxShadow: "none" }}
            >
              <span
                aria-hidden="true"
                itemProp="name"
                className=""
                style={{ outline: 0 }}
              >
                Cap
              </span>
            </a>
          </span>
          <span
            aria-hidden="true"
            className="px-1 text-zinc-800"
            style={{ outline: 0 }}
          >
            {`>`}
          </span>
        </span>
        <span className="leading-5" style={{ outline: 0 }}>
          <span className="text-zinc-800" style={{ outline: 0 }}>
            <a
              href="/"
              className="overflow-visible relative p-0 align-baseline bg-transparent border-0 cursor-pointer text-stone-500 focus:text-zinc-800 focus:outline-dashed focus:outline-1 hover:text-zinc-800"
              aria-label="Non-Fiction"
              data-test="@web/Breadcrumb/BreadcrumbLink"
              itemProp="url"
              style={{ boxShadow: "none" }}
            >
              <span
                aria-hidden="true"
                itemProp="name"
                className=""
                style={{ outline: 0 }}
              >
                Checo Perez
              </span>
            </a>
          </span>
          <span
            aria-hidden="true"
            className="text-zinc-800"
            style={{ outline: 0 }}
          ></span>
        </span>
      </div>
      {/* SECCION */}
      <div
        className="relative px-5 pt-0 pb-5 m-0 min-w-0 min-h-0 text-sm text-black md:flex md:pt-5 box-border mt-4"
        style={{ lineHeight: "1.4" }}
      >
        <div
          className="p-0 m-0 min-w-0 min-h-0 text-black md:w-1/2 box-border"
          style={{ lineHeight: "1.4" }}
        >
          <div
            className="relative py-0 px-8 m-0 min-w-0 min-h-0 text-center cursor-pointer md:relative md:cursor-pointer md:px-0 md:text-center box-border"
            style={{ lineHeight: "1.4" }}
          >
            <div
              className="inline-block p-0 m-0 min-w-0 min-h-0 box-border"
              style={{ lineHeight: "1.4" }}
            >
              <div
                className="p-0 m-0 min-w-0 min-h-0 box-border"
                style={{ lineHeight: "1.4" }}
              >
                <img
                  className="block p-0 m-auto w-full min-w-0 min-h-0 leading-5 border-none box-border"
                  src="https://m.media-amazon.com/images/I/61HzMcYp6aS._AC_UX679_.jpg"
                  alt=" Red Bull Racing F1 2022 Sergio Checo Perez Navy Hat"
                  style={{
                    animation:
                      "0.5s ease 0s 1 normal none running image_fadein__tdsTn",
                    maxWidth: 220,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-0 m-0 min-w-0 min-h-0 text-black md:w-1/2 md:pr-10 box-border"
          style={{ lineHeight: "1.4" }}
        >
          <div
            className="p-0 m-0 min-w-0 min-h-0 box-border"
            style={{ lineHeight: "1.4" }}
          >
            <h1
              className="p-0 mx-0 mt-2 mb-0 min-w-0 min-h-0 font-sans text-2xl font-normal leading-8 md:mb-2 box-border"
              itemProp="name"
              data-automation-id="productName"
            >
              Red Bull Racing F1 2021 Sergio Checo Perez Team Hat
            </h1>
          </div>
          <div
            itemProp="offers"
            itemScope=""
            itemType="http://schema.org/Offer"
            className="p-0 m-0 min-w-0 min-h-0 box-border"
            style={{ lineHeight: "1.4" }}
          >
            <meta
              itemProp="availability"
              content="https://schema.org/InStock"
              className="p-0 m-0 min-w-0 min-h-0 leading-5 box-border"
            />
            <meta
              itemProp="priceCurrency"
              content="MXN"
              className="p-0 m-0 min-w-0 min-h-0 leading-5 box-border"
            />
            <h4
              className="inline-block p-0 my-2 mx-0 min-w-0 min-h-0 font-sans text-base font-bold leading-6 box-border text-neutral-800"
              data-testid="price"
              data-gtm-vis-first-on-screen-1156430_708={1888}
              data-gtm-vis-first-on-screen-1156430_712={1889}
              data-gtm-vis-first-on-screen-1156430_1199={1891}
              data-gtm-vis-recent-on-screen-1156430_1199={8177}
              data-gtm-vis-total-visible-time-1156430_1199={100}
              data-gtm-vis-has-fired-1156430_1199={1}
              data-gtm-vis-recent-on-screen-1156430_712={8180}
              data-gtm-vis-total-visible-time-1156430_712={100}
              data-gtm-vis-has-fired-1156430_712={1}
              data-gtm-vis-recent-on-screen-1156430_708={8183}
              data-gtm-vis-total-visible-time-1156430_708={100}
              data-gtm-vis-has-fired-1156430_708={1}
            >
              <meta
                itemProp="price"
                content="$32.90"
                className="p-0 m-0 min-w-0 min-h-0 box-border"
              />
              $50.00
            </h4>
            <div
              className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 box-border"
              data-automation-id="promotions"
              style={{ lineHeight: "1.4" }}
            />
          </div>
          <div
            className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 text-center md:text-left box-border"
            style={{ lineHeight: "1.4" }}
          />
          <div
            className="relative p-0 m-0 w-full min-w-0 max-w-none min-h-0 box-border"
            style={{ lineHeight: "1.4" }}
          >
            <p
              className="p-0 m-0 min-w-0 h-5 min-h-0 font-sans text-left box-border text-neutral-400"
              style={{ fontSize: "11.2px", lineHeight: "1.4" }}
            />
            <button
              className="block overflow-visible p-3 m-0 w-full min-w-0 h-10 font-sans font-normal text-center normal-case bg-white rounded border border-solid cursor-pointer box-border border-sky-600 text-sky-600 hover:border hover:border-solid hover:border-sky-600 hover:text-sky-600"
              type="button"
              data-automation-id="addToCart"
              data-testid="add-to-cart"
              style={{
                minHeight: 44,
                lineHeight: "1.4",
                textDecoration: "none",
                outline: "none",
              }}
              onClick={() => navigate("/checkoutwalmart")}
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>
      {/* SECCION */}
      <div
        className="flex p-0 m-0 min-w-0 min-h-0 text-sm text-black box-border bg-neutral-100"
        tabstitles=""
        style={{ lineHeight: "1.4" }}
      >
        <div
          data-automation-id="tabTitle"
          active=""
          className="inline-block flex-grow-0 flex-shrink-0 p-0 m-0 w-1/4 min-w-0 min-h-0 font-bold bg-transparent border-b-4 border-solid cursor-auto box-border border-sky-600 text-zinc-800"
          style={{ width: "25%", lineHeight: "1.4", flexBasis: 150 }}
        >
          <div
            className="flex flex-col justify-center py-2 px-0 m-0 w-full min-w-0 h-full min-h-0 text-center box-border"
            style={{ lineHeight: "1.4" }}
          >
            Description
          </div>
        </div>
      </div>
      {/* SECCION */}
      <div
        className="py-2 px-5 m-0 min-w-0 min-h-0 text-sm text-black bg-white box-border"
        tabspanel=""
        data-testid="tab-panel"
        style={{ lineHeight: "1.4" }}
      >
        <p
          className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 font-sans text-base box-border text-zinc-700"
          itemProp="description"
          style={{ lineHeight: "1.4" }}
        />
        <p
          className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 font-sans text-base box-border text-zinc-700"
          itemProp="description"
          style={{ lineHeight: "1.4" }}
        />
        <p
          className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 font-sans text-base box-border text-zinc-700"
          itemProp="description"
          style={{ lineHeight: "1.4" }}
        >
          Red Bull Racing F1 2021 Sergio Checo Perez Team Hat
        </p>
        <p
          className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 font-sans text-base box-border text-zinc-700"
          itemProp="description"
          style={{ lineHeight: "1.4" }}
        >
          Style: Flatbrim
        </p>
        <p
          className="p-0 mx-0 mt-0 mb-2 min-w-0 min-h-0 font-sans text-base box-border text-zinc-700"
          itemProp="description"
          style={{ lineHeight: "1.4" }}
        >
          100% Polyester - Silicone team badge and number 11' on the crown -
          Adjustable cap closure
        </p>
      </div>
    </>
  );
}

export default WalmartShop;
