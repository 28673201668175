import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ModalEnvioShopify from "../components/ModalEnvioShopify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { size } from "lodash";
import DetailFunctionOrder from "./DetailFunctionOrder";

function EntregasShopify() {
  const [data, setData] = useState();
  const [seleccionados, setSeleccionados] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    fetchApiShopify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailCellRendererParams = { seleccionados };

  const detailCellRenderer = useMemo(() => {
    return DetailFunctionOrder;
  }, []);

  const [columnDefs] = useState([
    {
      field: "Seleccionar",
      headerName: "Seleccionar",
      checkboxSelection: true,
    },
    {
      field: "name",
      headerName: "Orden",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      cellRenderer: "agGroupCellRenderer",
    },
    {
      field: "id",
      headerName: "Id",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "email",
      headerName: "Correo",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "customer.first_name",
      headerName: "Cliente",
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
    },
  ]);

  const fetchApiShopify = async () => {
    fetch("https://t1-edi-nextjs.vercel.app/api/ordershopify", {
      method: "GET",
    })
      .then(async (res) => {
        const responeJSON = await res.json();
        const informacion = responeJSON.orders;
        console.log(informacion);
        setData(
          informacion.sort((a, b) => {
            a = new Date(a.created_at);
            b = new Date(b.created_at);
            return a > b ? -1 : a < b ? 1 : 0;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedRows();
    // var rowCount = event.api.getSelectedNodes();
    setSeleccionados(rowCount);
    console.log(rowCount);
  }, []);

  return (
    <>
      <div>
        <div className="mb-4">
          <h1 className="text-3xl font-bold text-[#D93A26]">
            Dashboard - Fullfilment Shopify
          </h1>
        </div>
      </div>
      <div className=" justify-between flex text-center">
        {size(seleccionados) === 0 ? (
          <div></div>
        ) : (
          <div className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-[#828282] rounded-lg border focus:z-10 focus:ring-4 flex items-center">
            {<div>{size(seleccionados)} seleccionados</div>}
          </div>
        )}
        {size(seleccionados) > 0 && (
          <div className="mb-2 flex flex-row">
            <ModalEnvioShopify seleccionados={seleccionados} country={"US"} />
            <ModalEnvioShopify seleccionados={seleccionados} country={"MX"} />
          </div>
        )}
      </div>
      <div
        className="ag-theme-alpine mb-7"
        style={{ height: 450, width: window.innerWidth - "20%" }}
      >
        <AgGridReact
          rowData={data}
          columnDefs={columnDefs}
          pagination={true}
          ref={gridRef}
          rowSelection="single"
          onSelectionChanged={onSelectionChanged}
          suppressMenuHide={true}
          rowGroupPanelShow={"always"}
          masterDetail={true}
          detailCellRenderer={detailCellRenderer}
          detailCellRendererParams={detailCellRendererParams}
        ></AgGridReact>
      </div>

      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  html,\n  body {\n    height: 100%;\n  }\n\n  @media (min-width: 640px) {\n    table {\n      display: inline-table !important;\n    }\n\n    thead tr:not(:first-child) {\n      display: none;\n    }\n  }\n\n  td:not(:last-child) {\n    border-bottom: 0;\n  }\n\n  th:not(:last-child) {\n    border-bottom: 2px solid rgba(0, 0, 0, .1);\n  }\n",
        }}
      />
    </>
  );
}

export default EntregasShopify;
