// eslint-disable-next-line import/no-anonymous-default-export

const EDI = () => {
  return [
    {
      interchanges: [
        {
          interchange_control_header_ISA: {
            authorization_information_qualifier_01:
              "no_authorization_information_present_no_meaningful_information_in_i02_00",
            authorization_information_02: "",
            security_information_qualifier_03:
              "no_security_information_present_no_meaningful_information_in_i04_00",
            security_information_04: "",
            interchange_id_qualifier_05: "mutually_defined_ZZ",
            interchange_sender_id_06: "AMAZON",
            interchange_id_qualifier_07: "duns_dun_bradstreet_01",
            interchange_receiver_id_08: "005447545",
            interchange_date_09: "200419",
            interchange_time_10: "2329",
            interchange_control_standards_identifier_11:
              "us_edi_community_of_asc_x12_tdcc_and_ucs_U",
            interchange_control_version_number_12: "00400",
            interchange_control_number_13: "000029141",
            acknowledgment_requested_14: "no_acknowledgment_requested_0",
            usage_indicator_15: "production_data_P",
            component_element_separator_16: ">",
          },
          groups: [
            {
              functional_group_header_GS: {
                functional_identifier_code_01: "purchase_order_850_PO",
                application_senders_code_02: "AMAZON",
                application_receivers_code_03: "005447545",
                date_04: "20200419",
                time_05: "2329",
                group_control_number_06: "29141",
                responsible_agency_code_07:
                  "accredited_standards_committee_x12_X",
                version_release_industry_identifier_code_08: "004010",
              },
              transaction_sets: [
                {
                  heading: {
                    transaction_set_header_ST: {
                      transaction_set_identifier_code_01: "850",
                      transaction_set_control_number_02: "0001",
                    },
                    beginning_segment_for_purchase_order_BEG: {
                      transaction_set_purpose_code_01: "original_00",
                      purchase_order_type_code_02: "new_order_NE",
                      purchase_order_number_03: "8IQB6SLL",
                      date_05: "20200419",
                    },
                    reference_identification_REF: [
                      {
                        reference_identification_qualifier_01:
                          "customer_reference_number_CR",
                        reference_identification_02: "REDFR",
                      },
                    ],
                    sales_requirements_CSH: [
                      {
                        sales_requirement_code_01:
                          "back_order_if_out_of_stock_Y",
                      },
                    ],
                    date_time_reference_DTM: [
                      {
                        date_time_qualifier_01: "do_not_deliver_before_064",
                        date_02: "20200420",
                      },
                      {
                        date_time_qualifier_01: "do_not_deliver_after_063",
                        date_02: "20200423",
                      },
                    ],
                    name_N1_loop: [
                      {
                        name_N1: {
                          entity_identifier_code_01: "ship_to_ST",
                          identification_code_qualifier_03:
                            "standard_address_number_san_15",
                          identification_code_04: "8014531",
                        },
                      },
                    ],
                  },
                  detail: {
                    baseline_item_data_PO1_loop: [
                      {
                        baseline_item_data_PO1: {
                          assigned_identification_01: "1",
                          quantity_ordered_02: "13",
                          unit_or_basis_for_measurement_code_03: "each_EA",
                          unit_price_04: "60",
                          basis_of_unit_price_code_05: "price_per_each_PE",
                          product_service_id_qualifier_06:
                            "upc_consumer_package_code_1_5_5_1_UP",
                          product_service_id_07: "027061210407",
                        },
                      },
                      {
                        baseline_item_data_PO1: {
                          assigned_identification_01: "2",
                          quantity_ordered_02: "7",
                          unit_or_basis_for_measurement_code_03: "each_EA",
                          unit_price_04: "44.3",
                          basis_of_unit_price_code_05: "price_per_each_PE",
                          product_service_id_qualifier_06:
                            "upc_consumer_package_code_1_5_5_1_UP",
                          product_service_id_07: "027061212692",
                        },
                      },
                      {
                        baseline_item_data_PO1: {
                          assigned_identification_01: "3",
                          quantity_ordered_02: "36",
                          unit_or_basis_for_measurement_code_03: "each_EA",
                          unit_price_04: "11.3",
                          basis_of_unit_price_code_05: "price_per_each_PE",
                          product_service_id_qualifier_06:
                            "upc_consumer_package_code_1_5_5_1_UP",
                          product_service_id_07: "027061215785",
                        },
                      },
                      {
                        baseline_item_data_PO1: {
                          assigned_identification_01: "4",
                          quantity_ordered_02: "10",
                          unit_or_basis_for_measurement_code_03: "each_EA",
                          unit_price_04: "35.4",
                          basis_of_unit_price_code_05: "price_per_each_PE",
                          product_service_id_qualifier_06:
                            "upc_consumer_package_code_1_5_5_1_UP",
                          product_service_id_07: "027061216607",
                        },
                      },
                      {
                        baseline_item_data_PO1: {
                          assigned_identification_01: "5",
                          quantity_ordered_02: "3",
                          unit_or_basis_for_measurement_code_03: "each_EA",
                          unit_price_04: "33.37",
                          basis_of_unit_price_code_05: "price_per_each_PE",
                          product_service_id_qualifier_06:
                            "upc_consumer_package_code_1_5_5_1_UP",
                          product_service_id_07: "027061216133",
                        },
                      },
                      {
                        baseline_item_data_PO1: {
                          assigned_identification_01: "6",
                          quantity_ordered_02: "24",
                          unit_or_basis_for_measurement_code_03: "each_EA",
                          unit_price_04: "66.3",
                          basis_of_unit_price_code_05: "price_per_each_PE",
                          product_service_id_qualifier_06:
                            "upc_consumer_package_code_1_5_5_1_UP",
                          product_service_id_07: "027061218670",
                        },
                      },
                    ],
                  },
                  summary: {
                    transaction_totals_CTT_loop: [
                      {
                        transaction_totals_CTT: {
                          number_of_line_items_01: "6",
                          hash_total_02: "93",
                        },
                      },
                    ],
                    transaction_set_trailer_SE: {
                      number_of_included_segments_01: "15",
                      transaction_set_control_number_02: "0001",
                    },
                  },
                  set: "850",
                },
              ],
              functional_group_trailer_GE: {
                number_of_transaction_sets_included_01: "1",
                group_control_number_02: "29141",
              },
              release: "004010",
            },
          ],
          interchange_control_trailer_IEA: {
            number_of_included_functional_groups_01: "1",
            interchange_control_number_02: "000029141",
          },
          delimiters: {
            element: "*",
            segment: "~",
            sub_element: ">",
          },
        },
      ],
      __version: "jedi@2.0",
    },
  ];
};

export default EDI;

// {
//       SegmentDelimiter: "~",
//       DataElementDelimiter: "*",
//       ISA: {
//         AuthorizationInformationQualifier_1: "00",
//         AuthorizationInformation_2: "          ",
//         SecurityInformationQualifier_3: "00",
//         SecurityInformation_4: "          ",
//         SenderIDQualifier_5: "ZZ",
//         InterchangeSenderID_6: "AMAZON         ",
//         ReceiverIDQualifier_7: "01",
//         InterchangeReceiverID_8: "005447545      ",
//         InterchangeDate_9: "200419",
//         InterchangeTime_10: "2329",
//         InterchangeControlStandardsIdentifier_11: "U",
//         InterchangeControlVersionNumber_12: "00400",
//         InterchangeControlNumber_13: "000029141",
//         AcknowledgementRequested_14: "0",
//         UsageIndicator_15: "P",
//         ComponentElementSeparator_16: ">",
//       },
//       Groups: [
//         {
//           GS: {
//             CodeIdentifyingInformationType_1: "PO",
//             SenderIDCode_2: "AMAZON",
//             ReceiverIDCode_3: "005447545",
//             Date_4: "20200419",
//             Time_5: "2329",
//             GroupControlNumber_6: "29141",
//             TransactionTypeCode_7: "X",
//             VersionAndRelease_8: "004010",
//           },
//           Transactions: [
//             {
//               ST: {
//                 TransactionSetIdentifierCode_01: "850",
//                 TransactionSetControlNumber_02: "0001",
//               },
//               BEG: {
//                 TransactionSetPurposeCode_01: "00",
//                 PurchaseOrderTypeCode_02: "NE",
//                 PurchaseOrderNumber_03: "8IQB6SLL",
//                 Date_05: "20200419",
//               },
//               REF: [
//                 {
//                   ReferenceIdentificationQualifier_01: "CR",
//                   ReferenceIdentification_02: "REDFR",
//                 },
//               ],
//               CSH: [
//                 {
//                   SalesRequirementCode_01: "Y",
//                 },
//               ],
//               DTM: [
//                 {
//                   DateTimeQualifier_01: "064",
//                   Date_02: "20200420",
//                 },
//                 {
//                   DateTimeQualifier_01: "063",
//                   Date_02: "20200423",
//                 },
//               ],
//               N1Loop: [
//                 {
//                   N1: {
//                     EntityIdentifierCode_01: "ST",
//                     IdentificationCodeQualifier_03: "15",
//                     IdentificationCode_04: "8014531",
//                   },
//                 },
//               ],
//               PO1Loop: [
//                 {
//                   PO1: {
//                     AssignedIdentification_01: "1",
//                     QuantityOrdered_02: "13",
//                     UnitorBasisforMeasurementCode_03: "EA",
//                     UnitPrice_04: "60",
//                     BasisofUnitPriceCode_05: "PE",
//                     ProductServiceIDQualifier_06: "UP",
//                     ProductServiceID_07: "027061210407",
//                   },
//                 },
//                 {
//                   PO1: {
//                     AssignedIdentification_01: "2",
//                     QuantityOrdered_02: "7",
//                     UnitorBasisforMeasurementCode_03: "EA",
//                     UnitPrice_04: "44.3",
//                     BasisofUnitPriceCode_05: "PE",
//                     ProductServiceIDQualifier_06: "UP",
//                     ProductServiceID_07: "027061212692",
//                   },
//                 },
//                 {
//                   PO1: {
//                     AssignedIdentification_01: "3",
//                     QuantityOrdered_02: "36",
//                     UnitorBasisforMeasurementCode_03: "EA",
//                     UnitPrice_04: "11.3",
//                     BasisofUnitPriceCode_05: "PE",
//                     ProductServiceIDQualifier_06: "UP",
//                     ProductServiceID_07: "027061215785",
//                   },
//                 },
//                 {
//                   PO1: {
//                     AssignedIdentification_01: "4",
//                     QuantityOrdered_02: "10",
//                     UnitorBasisforMeasurementCode_03: "EA",
//                     UnitPrice_04: "35.4",
//                     BasisofUnitPriceCode_05: "PE",
//                     ProductServiceIDQualifier_06: "UP",
//                     ProductServiceID_07: "027061216607",
//                   },
//                 },
//                 {
//                   PO1: {
//                     AssignedIdentification_01: "5",
//                     QuantityOrdered_02: "3",
//                     UnitorBasisforMeasurementCode_03: "EA",
//                     UnitPrice_04: "33.37",
//                     BasisofUnitPriceCode_05: "PE",
//                     ProductServiceIDQualifier_06: "UP",
//                     ProductServiceID_07: "027061216133",
//                   },
//                 },
//                 {
//                   PO1: {
//                     AssignedIdentification_01: "6",
//                     QuantityOrdered_02: "24",
//                     UnitorBasisforMeasurementCode_03: "EA",
//                     UnitPrice_04: "66.3",
//                     BasisofUnitPriceCode_05: "PE",
//                     ProductServiceIDQualifier_06: "UP",
//                     ProductServiceID_07: "027061218670",
//                   },
//                 },
//               ],
//               CTTLoop: {
//                 CTT: {
//                   NumberofLineItems_01: "6",
//                   HashTotal_02: "93",
//                 },
//               },
//               SE: {
//                 NumberofIncludedSegments_01: "15",
//                 TransactionSetControlNumber_02: "0001",
//               },
//               Model: "EdiNation.X12.ASC.004010",
//             },
//           ],
//           GETrailers: [
//             {
//               NumberOfIncludedSets_1: "1",
//               GroupControlNumber_2: "29141",
//             },
//           ],
//         },
//       ],
//       IEATrailers: [
//         {
//           NumberOfIncludedGroups_1: "1",
//           InterchangeControlNumber_2: "000029141",
//         },
//       ],
//       Result: {
//         LastIndex: 19,
//         Details: [],
//         Status: "success",
//       },
//     },
