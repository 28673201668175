import React from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  let navigate = useNavigate();

  return (
    <div>
      <div className="max-w-7xl mx-auto py-1 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-[#4C4C4C]">
          Mexican Manufacturing Companies
        </h1>
      </div>
      <div
        className={
          window.innerWidth <= 1024
            ? "flex items-center content-center justify-center flex-col mt-4"
            : "flex items-center content-center justify-center mt-4"
        }
      >
        <div
          className="max-w-sm rounded overflow-hidden shadow-lg mb-4 mx-4 cursor-pointer"
          onClick={() => navigate("/amazonshop")}
        >
          <img
            className="w-full p-2"
            //src="https://www.serfadu.com/wp-content/uploads/2020/10/amazon.png"
            src="pens.png"
            alt=""
            style={{
              height: "auto",
            }}
          />
        </div>
        <div
          className="max-w-sm rounded overflow-hidden shadow-lg mb-4 mx-4 cursor-pointer"
          onClick={() => navigate("/walmartshop")}
        >
          <img
            className="w-full  p-2"
            //src="https://www.america-retail.com/static//2019/01/walmart-logo-5.jpg"
            src="levolor.png"
            alt=""
            style={{
              height: "auto",
            }}
          />
        </div>
        <div
          className="max-w-sm rounded overflow-hidden shadow-lg mb-4 mx-4 cursor-pointer"
          onClick={() => navigate("/TargetShop")}
        >
          <img
            className="w-full p-2"
            //src="	https://logos-world.net/wp-content/uploads/2020/10/Target-Emblem.png"
            src="bose.png"
            alt=""
            style={{
              height: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
