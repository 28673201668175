import { Disclosure } from "@headlessui/react";
import logo from "../../src/assets/t1shopping.png";
import React from "react";

export default function LayoutWidget() {
  return (
    <div
      style={{
        position: "absolute",
        zindex: 6000,
        borderRadius: 0,
        maxWidth: "100vw",
        maxHeight: 200,
        top: 0,
        right: 0,
        left: 0,
      }}
    >
      <div className="min-h-full">
        <Disclosure
          as="nav"
          style={{
            backgroundColor: "#FFFFFF",
          }}
          className=" border-b-2"
        >
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center ">
                    <div className="flex-shrink-0 ">
                      <img className="h-10 " src={logo} alt="Workflow" />
                    </div>
                    <Disclosure.Button
                      style={{
                        position: "absolute",
                        right: "2%",
                      }}
                      className="bg-[#D93A26] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#D93A26] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        onClick={() => window.location.reload()}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </Disclosure.Button>
                  </div>
                  {/* <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="bg-[#335455] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#335455] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        onClick={() => window.location.reload()}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </Disclosure.Button>
                  </div> */}
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
