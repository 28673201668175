import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import generateJSON from "../lib/generateJSON";
import { size, isEmpty } from "lodash";
import { UseLocalStorage } from "../components/hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function CheckoutAmazon() {
  let navigate = useNavigate();

  const [adress, setAdress] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = UseLocalStorage("orders", "");
  var informacionOrden = {};
  const shop = "Amazon";
  const productPhoto =
    "https://m.media-amazon.com/images/I/81V1Jz-IfPL._AC_UY350_.jpg";
  const product = "Red Bull Racing F1 2022 Sergio Checo Perez Navy Hat";
  const id = "62fbc9ffa424480e07cdb068";

  function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  const handledBuyClick = () => {
    if (isEmpty(name)) {
      swal({
        title: "Enter your name",
        icon: "warning",
      });
    } else if (isEmpty(adress)) {
      swal({
        title: "Enter delivery address",
        icon: "warning",
      });
    } else {
      //TODO generar aleatoreamente el ID de la Orden
      // eslint-disable-next-line no-unused-vars
      const json = generateJSON({
        orderNumber: generateUUID(),
        dateOrder: new Date(),
        shop,
        adress,
        name,
        product,
        productPhoto,
        id,
      });

      //TODO Pushearlo al Array que existe en el local storage "orders"
      if (size(order) > 0) {
        informacionOrden = [...order, json];
        setOrder(informacionOrden);
      } else {
        informacionOrden = [json];
        setOrder(informacionOrden);
      }
      swal({
        title: "Success",
        text: "The order has been placed successfully",
        icon: "success",
      }).then((res) => {
        if (res) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
    }
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');\nhtml {font-family: \"PT Sans\", sans-serif;}\n",
        }}
      />

      <div className="flex flex-col ">
        <div
          className="pt-3 pb-1 px-3 flex flex-col "
          style={{ backgroundColor: "#232f3e" }}
        >
          <div className="flex flex-row justify-between items-center mb-2 py-1 ">
            <div className="flex flex-row items-center">
              <svg
                className="w-6 h-6 text-white mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6"
                id="svg1936"
                version={1.0}
                viewBox="0 0 1000 301.4"
              >
                <path
                  id="path8"
                  fill="#f90"
                  fillOpacity={1}
                  fillRule="evenodd"
                  stroke="none"
                  d="M620 236a389 389 0 01-477-34c-5-6-1-12 6-9a528 528 0 00463 29c10-4 18 7 8 14"
                />
                <path
                  id="path10"
                  fill="#f90"
                  fillOpacity={1}
                  fillRule="evenodd"
                  stroke="none"
                  d="M645 208c-8-9-50-4-68-2-6 0-7-5-2-8 33-23 88-17 94-9 7 8-1 63-33 89-4 4-9 2-7-4 7-17 23-56 16-66"
                />
                <path
                  id="path12"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M578 33V10c0-3 3-6 6-6h102c3 0 5 3 5 6v20c0 3-2 7-7 14l-53 75c20 0 40 3 58 13 4 2 5 5 5 8v25c0 3-3 7-7 5-31-17-73-18-108 0-3 2-7-2-7-5v-23c0-4 0-10 4-16l61-87h-53c-3 0-6-3-6-6"
                />
                <path
                  id="path14"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M207 175h-31c-3-1-5-3-6-6V11c0-4 3-6 6-6h29c3 0 6 2 6 5v21c8-20 22-29 41-29 20 0 32 9 40 29 8-20 25-29 43-29 13 0 27 5 36 17 10 13 8 33 8 50v100c0 3-3 6-6 6h-31c-3-1-6-3-6-6V85c0-7 1-24-1-30-2-11-9-14-18-14-7 0-15 5-18 13s-3 22-3 31v84c0 3-3 6-6 6h-31c-3-1-6-3-6-6V85c0-18 3-44-19-44s-21 25-21 44v84c0 3-3 6-6 6"
                />
                <path
                  id="path16"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M779 2c46 0 71 39 71 89 0 49-28 87-71 87-45 0-70-39-70-89 0-49 25-87 70-87m0 32c-23 0-24 31-24 50 0 20 0 61 24 61s25-33 25-54c0-13 0-29-5-42-3-11-10-15-20-15"
                />
                <path
                  id="path18"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M909 175h-31c-3-1-5-3-5-6V10c0-3 3-5 6-5h29c2 0 4 2 5 4v25h1c8-22 20-32 42-32 14 0 27 5 36 18 8 13 8 34 8 50v100c0 2-3 5-6 5h-31c-3-1-5-3-5-5V83c0-17 2-42-20-42-7 0-14 5-18 12-4 10-5 20-5 30v86c0 3-2 6-6 6"
                />
                <path
                  id="path28"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M497 99c0 12 0 22-6 33-5 8-13 14-21 14-12 0-19-9-19-23 0-26 24-31 46-31v7m31 75c-2 2-5 2-7 1-10-9-12-13-18-21-17 18-29 23-51 23-26 0-46-16-46-48 0-25 13-43 33-51 17-7 40-9 58-11v-4c0-7 0-16-4-22s-11-8-17-8c-12 0-23 6-25 18-1 3-3 6-5 6l-30-3c-3-1-6-3-5-7 7-36 40-47 69-47 15 0 35 4 47 15 15 14 13 33 13 54v48c0 14 6 21 12 29 2 2 2 6 0 8l-24 20"
                />
                <path
                  id="path30"
                  fill="#fff"
                  fillRule="evenodd"
                  stroke="none"
                  d="M91 99c0 12 0 22-6 33-5 8-12 14-21 14-12 0-19-9-19-23 0-26 24-31 46-31v7m31 75c-2 2-5 2-7 1-10-9-12-13-18-21-17 18-29 23-51 23-26 0-46-16-46-48 0-25 14-43 33-51 17-7 40-9 58-11v-4c0-7 1-16-4-22-3-6-11-8-17-8-12 0-22 6-25 18 0 3-2 6-5 6l-30-3c-3-1-6-3-5-7C12 11 45 0 74 0c15 0 35 4 47 15 15 14 14 33 14 54v48c0 14 6 21 11 29 2 2 3 6 0 8l-24 20"
                />
              </svg>
            </div>
            <div className="flex flex-row">
              <div
                className="mr-10 bg-yellow-400 rounded-md p-1 pl-4 pr-4 cursor-pointer text-sky-900 font-medium "
                onClick={() => navigate("/")}
              >
                Home
              </div>

              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="mt-8 ml-4 text-xl">Select a payment method</div>
        <div className="flex-row flex bg-slate-100 m-4 items-center">
          <div className="bg-slate-300  m-2 text-center w-32 rounded-md p-1">
            Learn more
          </div>
          <div>
            FLEX, your cost could be $0.00 instead of $49.98! Get a $50 Amazon
            Gift Card instantly upon approval of the Amazon Rewards Visa Card.
          </div>
        </div>

        <div className="m-4 border-2 p-3">
          <div className="font-bold ">Your credit and debit cards</div>
          <div>Start by registering a card</div>
        </div>
        <div className="mt-2 ml-4 text-xl flex-row flex">
          <div>Enter your name</div>
          <div className="text-red-600 ">*</div>
        </div>
        <div className="mx-4">
          <input
            className="w-full"
            onChange={(e) => setName(e.target.value)}
            name="name"
            type="text"
            value={name}
            placeholder="My name"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
            }}
          />
        </div>
        <div className="mt-2 ml-4 text-xl flex-row flex">
          <div>Select delivery address </div>
          <div className="text-red-600 "> *</div>
        </div>
        <Autocomplete
          apiKey={"AIzaSyCSfb3q43wvrhTk9tbipj9KkrcVcjxW3ro"}
          onPlaceSelected={(place) => {
            setAdress(place.formatted_address);
          }}
          defaultValue={adress}
          placeholder="Find address"
          style={{
            margin: 15,
            borderWidth: 1,
            padding: 5,
            borderRadius: 4,
          }}
          options={{
            types: ["address"],
            componentRestrictions: { country: "us" },
          }}
        />
        {/*<div className="mx-4">
          <input
            className="w-full"
            onChange={(e) => setAdress(e.target.value)}
            name="address"
            type="text"
            value={adress}
            placeholder="Address"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
            }}
          />
          </div>*/}

        <button
          className="rounded px-2 py-3 border border-yellow-700 bg-gradient-to-b from-yellow-500 to-yellow-600 w-36 mt-6 ml-4"
          onClick={handledBuyClick}
        >
          Order
        </button>
      </div>
    </>
  );
}

export default CheckoutAmazon;
