import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import generateJSON from "../lib/generateJSON";
import { size, isEmpty } from "lodash";
import { UseLocalStorage } from "../components/hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function CheckoutWalmart(props) {
  let navigate = useNavigate();

  const [adress, setAdress] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = UseLocalStorage("orders", "");
  var informacionOrden = {};
  const shop = "Walmart";
  const productPhoto =
    "https://m.media-amazon.com/images/I/61HzMcYp6aS._AC_UX679_.jpg";
  const product = "Red Bull Racing F1 2021 Sergio Checo Perez Team Hat";
  const id = "62fbca6da424480e07cdb0b2";

  function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  const handledBuyClick = () => {
    if (isEmpty(name)) {
      swal({
        title: "Enter your name",
        icon: "warning",
      });
    } else if (isEmpty(adress)) {
      swal({
        title: "Enter delivery address",
        icon: "warning",
      });
    } else {
      //TODO generar aleatoreamente el ID de la Orden
      // eslint-disable-next-line no-unused-vars
      const json = generateJSON({
        orderNumber: generateUUID(),
        dateOrder: new Date(),
        shop,
        adress,
        name,
        product,
        productPhoto,
        id,
      });

      //TODO Pushearlo al Array que existe en el local storage "orders"
      if (size(order) > 0) {
        informacionOrden = [...order, json];
        setOrder(informacionOrden);
      } else {
        informacionOrden = [json];
        setOrder(informacionOrden);
      }
      swal({
        title: "Pedido exitoso",
        text: "Se ha realizado el pedido correctamente",
        icon: "success",
      }).then((res) => {
        if (res) {
          navigate("/");
        } else {
          navigate("/");
        }
      });
    }
  };

  return (
    <>
      <div
        className="relative py-2 px-5 m-0 min-w-0 min-h-0 text-sm leading-4 box-border text-zinc-700"
        style={{
          backgroundColor: "#347BC1",
        }}
      >
        <div className="flex justify-between items-center p-0 m-0 min-w-0 min-h-0 leading-4 box-border">
          <div
            className="flex p-0 m-0 min-h-0 box-border"
            style={{ minWidth: 150 }}
          >
            <div className="flex p-0 m-0 min-w-0 min-h-0 box-border">
              <a
                href="/"
                className="flex items-center py-0 px-2 m-0 min-w-0 min-h-0 font-sans cursor-pointer box-border"
                style={{ textDecoration: "none" }}
              >
                <img
                  className="flex-grow flex-shrink-0 p-0 m-0 w-full min-w-0 h-full min-h-0 border-none box-border"
                  src="https://ingenieria.udd.cl/files/2020/09/walmart-logo-1-1.jpg"
                  alt="walmart logo"
                  data-automation-id="walmart-logo-image"
                  style={{ maxWidth: 110 }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* SECCION */}
      <div
        className="m-8"
        style={{
          borderWidth: 1,
          padding: 20,
        }}
      >
        <div className="flex  justify-between">
          <div>Subotal</div>
          <div>$50.00</div>
        </div>
        <div className="flex justify-between">
          <div>Delivery cost</div>
          <div>$50.00</div>
        </div>
        <div className="flex justify-between">
          <div>Total</div>
          <div>$50.00</div>
        </div>
      </div>
      {/* SECCION */}
      <div className="m-4">
        <div className="mt-2 ml-4 text-xl flex-row flex mb-3">
          <div>Enter your name</div>
          <div className="text-red-600 ">*</div>
        </div>
        <div className="mx-4">
          <input
            className="w-full"
            onChange={(e) => setName(e.target.value)}
            name="name"
            type="text"
            value={name}
            placeholder="My name"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
            }}
          />
        </div>
        <div className="mt-2 ml-4 text-xl flex-row flex mb-3">
          <div>Select delivery address </div>
          <div className="text-red-600 "> *</div>
        </div>
        <div className="mx-4">
          <Autocomplete
            apiKey={"AIzaSyCSfb3q43wvrhTk9tbipj9KkrcVcjxW3ro"}
            onPlaceSelected={(place) => {
              setAdress(place.formatted_address);
            }}
            className="w-full"
            defaultValue={adress}
            placeholder="Find address"
            style={{
              borderWidth: 1,
              padding: 5,
              borderRadius: 4,
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
          />
        </div>
        <button
          className="rounded px-2 py-3 border border-blue-500 bg-gradient-to-b from-blue-500 to-blue-400 w-36 mt-6 text-white ml-4"
          onClick={handledBuyClick}
        >
          Order
        </button>
      </div>
    </>
  );
}

export default CheckoutWalmart;
